import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ReactFlowProvider } from "@xyflow/react";
import { TabPanelContainer, TabWrapper, SettingsForm } from "./SettingsStyles";
import WorkingHoursCalendar from "./WorkingHoursCalendar";
import { getData, patchData, postData } from "../../services/Api";
import Editor from "../../components/Editor/Editor";
import { useTasks } from "../../context/TasksContext";

const Settings = ({ setDisplayMessage }) => {
  const reactFlowInstance = useRef(null);
  const { t } = useTranslation("settings");
  const [selectedTab, setSelectedTab] = useState(0);
  const [workingHours, setWorkingHours] = useState([]);
  const [staleTimeout, setStaleTimeout] = useState(30);
  const [staleTimeoutUnit, setStaleTimeoutUnit] = useState("minutes");
  const [tolerance, setTolerance] = useState(3);
  const [webhooks, setWebhooks] = useState([]);
  const [selectedWebhook, setSelectedWebhook] = useState(null);
  const [newToken, setNewToken] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { tasks, setTasks } = useTasks();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [webhookData, clientConfig, clientTasks] = await Promise.all([
          getData("/backoffice/clients/webhooks"),
          getData("/backoffice/clients/"),
          getData("/backoffice/clients/"),
        ]);

        setWebhooks(webhookData);

        // Process working hours
        if (clientConfig.working_hours) {
          const weekdayMap = {
            Sunday: 0,
            Monday: 1,
            Tuesday: 2,
            Wednesday: 3,
            Thursday: 4,
            Friday: 5,
            Saturday: 6,
          };

          const today = moment().startOf("week"); // Start of the current week
          const formattedHours = Object.entries(
            clientConfig.working_hours
          ).flatMap(([day, slots]) => {
            const dayIndex = weekdayMap[day];
            const targetDate = today.clone().add(dayIndex, "days"); // Get the correct date for the day

            return slots.map((slot) => {
              const [startHour, startMinute] = slot.start
                .split(":")
                .map(Number);
              const [endHour, endMinute] = slot.end.split(":").map(Number);

              return {
                start: targetDate
                  .clone()
                  .set({ hour: startHour, minute: startMinute })
                  .toDate(),
                end: targetDate
                  .clone()
                  .set({ hour: endHour, minute: endMinute })
                  .toDate(),
                day,
              };
            });
          });
          setWorkingHours(formattedHours);
        }

        // Handle stale timeout
        const timeUnitConversion = {
          weeks: 604800,
          days: 86400,
          hours: 3600,
          minutes: 60,
          seconds: 1,
        };

        let selectedUnit = "seconds";
        let selectedValue = clientConfig.stale_timeout;

        for (const [unit, valueInSeconds] of Object.entries(
          timeUnitConversion
        )) {
          if (clientConfig.stale_timeout % valueInSeconds === 0) {
            selectedUnit = unit;
            selectedValue = clientConfig.stale_timeout / valueInSeconds;
            break;
          }
        }

        setStaleTimeout(selectedValue);
        setStaleTimeoutUnit(selectedUnit);

        if (clientConfig.tolerance) {
          setTolerance(clientConfig.tolerance);
        }

        setTasks(clientTasks.config.agent.tasks);
        setDisplayMessage(t("data_loaded_success"), "success");
      } catch (error) {
        console.error("Error fetching data:", error);
        setDisplayMessage(t("data_loaded_error"), "error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSaveAllConfig = async () => {
    setIsLoading(true);
    try {
      const timeUnitConversion = {
        seconds: 1,
        minutes: 60,
        hours: 3600,
        days: 86400,
        weeks: 604800,
      };
      const staleTimeoutInSeconds =
        staleTimeout * (timeUnitConversion[staleTimeoutUnit] || 1);

      const parsedWorkingHours = workingHours.map((x) => {
        return {
          day: moment(x["start"]).format("dddd"),
          start: moment(x["start"]).format("HH:mm"),
          end: moment(x["end"]).format("HH:mm"),
        };
      });

      const dictWorkingHours = {};
      for (let e in parsedWorkingHours) {
        const element = parsedWorkingHours[e];
        if (!dictWorkingHours[element["day"]]) {
          dictWorkingHours[element["day"]] = [];
        }
        dictWorkingHours[element["day"]].push({
          start: element["start"],
          end: element["end"],
        });
      }

      const payload = {
        working_hours: dictWorkingHours,
        stale_timeout: staleTimeoutInSeconds, // Send in seconds
        tolerance: tolerance,
      };

      await patchData("/backoffice/clients/config", JSON.stringify(payload));
      setDisplayMessage(t("config_saved_success"), "success");
    } catch (error) {
      console.error("Error saving configuration:", error.message);
      setDisplayMessage(t("config_saved_error"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddWebhook = async () => {
    setIsLoading(true);
    try {
      const newWebhook = { webhook_id: "", token: newToken }; // Replace webhook_id with proper ID
      await postData("/backoffice/clients/webhooks", newWebhook);
      setWebhooks([...webhooks, newWebhook]);
      setNewToken("");
      setDisplayMessage(t("webhook_added_success"), "success");
    } catch (error) {
      console.error("Error adding webhook:", error.message);
      setDisplayMessage(t("webhook_added_error"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditWebhook = async (webhookId) => {
    setIsLoading(true);
    try {
      await patchData(`/backoffice/clients/webhooks/${webhookId}`, {
        token: newToken,
      });
      setWebhooks(
        webhooks.map((wh) =>
          wh.webhook_id === webhookId
            ? { ...wh, config: { token: newToken } }
            : wh
        )
      );
      setNewToken("");
      setShowDialog(false);
      setDisplayMessage(t("webhook_updated_success"), "success");
    } catch (error) {
      console.error("Error updating webhook:", error.message);
      setDisplayMessage(t("webhook_updated_error"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteWebhook = async (webhookId) => {
    setIsLoading(true);
    try {
      await postData(`/backoffice/clients/webhooks/${webhookId}`, {
        delete: true,
      });
      setWebhooks(webhooks.filter((wh) => wh.webhook_id !== webhookId));
      setDisplayMessage(t("webhook_deleted_success"), "success");
    } catch (error) {
      console.error("Error deleting webhook:", error.message);
      setDisplayMessage(t("webhook_deleted_error"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCloseError = () => {
    setDisplayMessage();
  };

  const handleOpenDialog = (webhook) => {
    setSelectedWebhook(webhook);
    setNewToken("");
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setSelectedWebhook(null);
  };

  return (
    <Box>
      {isLoading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 2000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ marginTop: 4 }}
      >
        <Tab label={t("general_configuration_tab")} />
        <Tab label={t("agent_configuration_tab")} />
        <Tab label={t("webhook_configuration_tab")} />
      </Tabs>
      <TabWrapper>
        {selectedTab === 0 && (
          <TabPanelContainer>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              {t("working_hours_title")}
            </Typography>

            {/* Calendar for Working Hours */}
            <WorkingHoursCalendar
              workingHours={workingHours}
              setWorkingHours={setWorkingHours}
              setDisplayMessage={setDisplayMessage}
            />

            {/* Stale Timeout */}
            <Typography variant="h6" sx={{ marginTop: 4 }}>
              {t("stale_timeout_title")}
            </Typography>
            <SettingsForm>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    label={t("timeout_duration")}
                    type="number"
                    value={staleTimeout}
                    onChange={(e) => setStaleTimeout(e.target.value)}
                    InputLabelProps={{ shrink: true }} // Ensures label shrinks correctly
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink>{t("timeout_unit")}</InputLabel>
                    <Select
                      value={staleTimeoutUnit}
                      onChange={(e) => setStaleTimeoutUnit(e.target.value)}
                      label={t("timeout_unit")}
                    >
                      <MenuItem value="seconds">{t("seconds")}</MenuItem>
                      <MenuItem value="minutes">{t("minutes")}</MenuItem>
                      <MenuItem value="hours">{t("hours")}</MenuItem>
                      <MenuItem value="days">{t("days")}</MenuItem>
                      <MenuItem value="weeks">{t("weeks")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </SettingsForm>

            {/* Tolerance */}
            <Typography variant="h6" sx={{ marginTop: 4 }}>
              {t("tolerance_title")}
            </Typography>
            <SettingsForm>
              <TextField
                label={t("tolerance_value")}
                type="number"
                value={tolerance}
                onChange={(e) => setTolerance(e.target.value)}
                fullWidth
              />
            </SettingsForm>
            <Button
              onClick={handleSaveAllConfig}
              variant="contained"
              sx={{ marginTop: 4 }}
            >
              {t("save_button")}
            </Button>
          </TabPanelContainer>
        )}
        {selectedTab === 1 && (
          <ReactFlowProvider
            onInit={(instance) => (reactFlowInstance.current = instance)}
          >
            <Editor />
          </ReactFlowProvider>
        )}
        {selectedTab === 2 && (
          <TabPanelContainer>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              {t("webhook_configuration_title")}
            </Typography>

            <SettingsForm>
              {webhooks.map((webhook) => (
                <Box key={webhook.webhook_id} sx={{ marginBottom: 2 }}>
                  <Typography>
                    <strong>ID:</strong> {webhook.webhook_id}
                  </Typography>
                  <Button
                    onClick={() => handleOpenDialog(webhook)}
                    variant="contained"
                    sx={{ marginRight: 2 }}
                  >
                    {t("edit_token")}
                  </Button>
                  <Button
                    onClick={() => handleDeleteWebhook(webhook.webhook_id)}
                    color="error"
                    variant="outlined"
                  >
                    {t("delete_webhook")}
                  </Button>
                </Box>
              ))}

              <TextField
                label={t("new_token")}
                value={newToken}
                onChange={(e) => setNewToken(e.target.value)}
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <Button onClick={handleAddWebhook} variant="contained">
                {t("add_webhook")}
              </Button>
            </SettingsForm>

            <Dialog
              open={showDialog}
              onClose={handleCloseDialog}
              PaperProps={{
                style: {
                  borderRadius: "12px",
                  padding: "20px",
                  width: "400px",
                },
              }}
            >
              <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
                {t("edit_webhook_token")}
              </DialogTitle>
              <Box sx={{ margin: "20px 0" }}>
                <TextField
                  label={t("new_token")}
                  value={newToken}
                  onChange={(e) => setNewToken(e.target.value)}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <DialogActions sx={{ justifyContent: "center" }}>
                <Button
                  onClick={handleCloseDialog}
                  sx={{ marginRight: "10px" }}
                  color="primary"
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={() => handleEditWebhook(selectedWebhook.webhook_id)}
                  variant="contained"
                  color="primary"
                >
                  {t("save_button")}
                </Button>
              </DialogActions>
            </Dialog>
          </TabPanelContainer>
        )}
      </TabWrapper>
    </Box>
  );
};

export default Settings;
