import { parsePhoneNumberFromString } from "libphonenumber-js"; // Import libphonenumber-js

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { FaDiscord } from "react-icons/fa";

export const getCountryFlagAndUserId = (userId) => {
  let countryFlag = "🏳️";
  let cleanedUserId = userId;
  const phoneNumberObject = parsePhoneNumberFromString(`+${userId}`, "");
  if (phoneNumberObject && phoneNumberObject.country) {
    countryFlag = phoneNumberObject.country;
    cleanedUserId = userId
      .replace(phoneNumberObject.countryCallingCode, "")
      .replace(/^\s+|\s+$/g, "");
  }
  return countryFlag + " " + cleanedUserId;
};

export const getChatTimestamp = (lastMessage) => {
  return lastMessage.timestamp
    ? (() => {
        const messageDate = new Date(lastMessage.timestamp);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        if (messageDate < yesterday) {
          return messageDate.toLocaleString([], {
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          });
        } else {
          return messageDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
        }
      })()
    : "";
};

export const getChatTitle = (chat) => {
  const chat_info = chat.user_id.split("~");
  const chat_origin = chat_info[0];
  return chat.chat_id.startsWith("whatsapp:") ? (
    <>
      <WhatsAppIcon color="primary" sx={{ marginBottom: "-.75vh" }} />
      {" " + getCountryFlagAndUserId(chat.user_id.split(":")[1])}
    </>
  ) : chat.chat_id.startsWith("discord:") ? (
    <>
      <FaDiscord color="primary" sx={{ marginBottom: "-.75vh" }} />{" "}
      {chat_info[chat_info.length - 1]}
    </>
  ) : chat_origin === "unknown" ? (
    <>
      <PersonOffIcon color="action" sx={{ marginBottom: "-.75vh" }} />{" "}
      {chat_info[chat_info.length - 1]}
    </>
  ) : (
    <>
      <PersonIcon color="primary" sx={{ marginBottom: "-.75vh" }} />{" "}
      {chat_info[chat_info.length - 1]}
    </>
  );
};
