import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Divider,
  Typography,
  Select,
  MenuItem,
  IconButton,
  CircularProgress,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import { Sidebar, CenteredModeButtons } from "./ChatsViewStyles";
import { hasPrivilege } from "../../utils/AuthUtils";
import { patchData } from "../../services/Api";

const ChatInformation = ({
  isRightSidebarOpen,
  chatMode,
  selectedChat,
  loadingChat,
  closingChat,
  renderMetadataTable,
  handleCloseChatConfirm,
  rightSidebarSize,
  rightSidebarRef,
  toggleRightSidebar,
  handleChangeMode,
  isCloseDialogOpen,
  setIsCloseDialogOpen,
  isRestartDialogOpen,
  setIsRestartDialogOpen,
  handlRestartChatConfirm,
}) => {
  const { t } = useTranslation("chats");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [labels, setLabels] = useState(selectedChat?.labels || []);
  const [newLabel, setNewLabel] = useState({ label: "", color: "#888888" });
  const [showColorPicker, setShowColorPicker] = useState(false);

  useEffect(() => {
    if (selectedChat) {
      const validLabels = (selectedChat.labels || []).filter(
        (label) => label && label.label && label.color
      );
      setLabels(validLabels); // Update labels when selectedChat changes
    }
  }, [selectedChat]);

  const handleLabelChange = (event) => {
    const updatedLabels = event.target.value.filter(
      (label) => label && label.label && label.color
    );
    setLabels(updatedLabels);

    // Save changes to backend
    patchData(`/backoffice/chats/${selectedChat.chat_id}/labels`, {
      user_id: selectedChat.user_id,
      labels: updatedLabels,
    }).catch((error) => {
      console.error("Failed to update labels:", error);
    });
  };

  const handleAddNewLabel = () => {
    if (!newLabel.label.trim()) return;

    const updatedLabel = { ...newLabel };
    const updatedLabels = [...labels, updatedLabel];

    setLabels(updatedLabels);

    // Reset newLabel state
    setNewLabel({ label: "", color: "#888888" });

    // Save new label to backend
    patchData(`/backoffice/chats/${selectedChat.chat_id}/labels`, {
      user_id: selectedChat.user_id,
      labels: updatedLabels,
    }).catch((error) => {
      console.error("Failed to add label:", error);
    });
  };

  const processDescription = (description, t) => {
    return description.replace(/<([^>]+)>/g, (match, p1) => {
      const translated = t(p1);
      return translated === p1 ? p1 : translated;
    });
  };

  const handleDeleteLabel = (labelToDelete) => {
    // Optimistically update the UI
    const updatedLabels = labels.filter(
      (label) => label.label !== labelToDelete.label
    );
    setLabels(updatedLabels);

    // Send DELETE request to backend
    patchData(`/backoffice/chats/${selectedChat.chat_id}/labels`, {
      user_id: selectedChat.user_id,
      labels: updatedLabels,
      delete: [labelToDelete], // Indicating the label(s) to be removed
    }).catch((error) => {
      console.error("Failed to delete label:", error);
      // Rollback UI in case of an error
      setLabels(labels);
    });
  };

  const detection = selectedChat?.detection;
  const deviceType = detection?.device_type;
  const deviceBrand = detection?.device_brand;
  const deviceModel = detection?.device_model;

  const metadata = {
    state: { ...selectedChat?.state_metadata?.state },
    current_state: selectedChat?.state_metadata?.current_state,
  };

  if (metadata.current_state && metadata.current_state.description) {
    metadata.current_state.description = processDescription(
      metadata.current_state.description,
      t
    );
  }

  if (deviceType && deviceType !== "unknown") {
    metadata["detection"] = metadata?.detection || {};
    metadata["detection"]["device_type"] = deviceType;
  }
  if (deviceBrand && deviceBrand !== "unknown") {
    metadata["detection"] = metadata?.detection || {};
    metadata["detection"]["device_brand"] = deviceBrand;
  }
  if (deviceModel && deviceModel !== "unknown" && deviceModel.length) {
    metadata["detection"] = metadata?.detection || {};
    if (deviceModel.length === 1) {
      metadata["detection"]["device_model"] = deviceModel[0];
    } else {
      metadata["detection"]["device_model"] = deviceModel;
    }
  }
  const formattedCreatedAt = selectedChat
    ? new Date(selectedChat.created_at).toLocaleString()
    : "";
  const formattedUpdatedAt = selectedChat
    ? new Date(selectedChat.updated_at).toLocaleString()
    : "";

  // Render confirmation dialog
  const renderCloseChatDialog = () => (
    <Dialog
      open={isCloseDialogOpen}
      onClose={() => setIsCloseDialogOpen(false)}
      aria-labelledby="close-chat-dialog-title"
    >
      <DialogTitle id="close-chat-dialog-title">
        {t("confirm_close_chat")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("are_you_sure_close_chat")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsCloseDialogOpen(false)}>
          {t("cancel")}
        </Button>
        <Button
          onClick={handleCloseChatConfirm}
          color="primary"
          disabled={closingChat}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderRestartChatDialog = () => (
    <Dialog
      open={isRestartDialogOpen}
      onClose={() => setIsRestartDialogOpen(false)}
      aria-labelledby="restart-chat-dialog-title"
    >
      <DialogTitle id="restart-chat-dialog-title">
        {t("confirm_restart_chat")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t("are_you_sure_restart_chat")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsRestartDialogOpen(false)}>
          {t("cancel")}
        </Button>
        <Button
          onClick={handlRestartChatConfirm}
          color="primary"
          disabled={closingChat}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Grid
      item
      xs={rightSidebarSize[0]}
      sm={rightSidebarSize[1]}
      md={rightSidebarSize[2]}
      style={{ height: "90vh" }}
    >
      {loadingChat ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Sidebar
          ref={rightSidebarRef}
          isSidebarOpen={isRightSidebarOpen}
          isMobile={isMobile}
        >
          <IconButton
            onClick={toggleRightSidebar}
            style={{ float: "right", margin: "1vh" }}
          >
            <CloseIcon />
          </IconButton>
          {hasPrivilege("backoffice_chat", "edit") && (
            <Box>
              <CenteredModeButtons>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel>{t("select_chat_mode")}</InputLabel>
                  <Select
                    value={chatMode}
                    onChange={handleChangeMode}
                    displayEmpty
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="ai">{t("ai_mode")}</MenuItem>
                    <MenuItem value="copilot">{t("copilot_mode")}</MenuItem>
                    <MenuItem value="human">{t("manual_mode")}</MenuItem>
                  </Select>
                </FormControl>
              </CenteredModeButtons>
            </Box>
          )}

          {hasPrivilege("backoffice_chat", "edit") && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              {labels && labels.length > 0 ? (
                <>
                  <Typography variant="subtitle1">
                    {t("current_labels")}
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}
                  >
                    {labels.map((label, index) =>
                      label ? (
                        <Chip
                          key={`${label.label}-${index}`}
                          label={
                            <Box display="flex" alignItems="center">
                              {label.label}
                              <IconButton
                                size="small"
                                onClick={() => handleDeleteLabel(label)}
                                sx={{ marginLeft: 1 }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          }
                          sx={{
                            backgroundColor: label.color || "#888888",
                            color: "#FFF",
                          }}
                        />
                      ) : null
                    )}
                  </Box>
                </>
              ) : (
                <Typography variant="subtitle1">
                  {t("no_current_labels")}
                </Typography>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowColorPicker((prev) => !prev)}
                sx={{
                  mb: 2,
                  textAlign: "center",
                }}
              >
                {showColorPicker ? t("hide_label_creator") : t("add_new_label")}
              </Button>
              {showColorPicker && (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: 3,
                    backgroundColor: "#f9f9f9",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: "100%" }}
                  >
                    <OutlinedInput
                      placeholder={t("create_new_label")}
                      value={newLabel.label}
                      onChange={(e) =>
                        setNewLabel((prev) => ({
                          ...prev,
                          label: e.target.value,
                        }))
                      }
                      fullWidth
                      sx={{
                        mb: 2,
                        "& .MuiOutlinedInput-input": {
                          textAlign: "center",
                          padding: "12px",
                        },
                      }}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ mb: 2 }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ mb: 1, color: "#666" }}
                      >
                        {t("select_color")}
                      </Typography>
                      <Box
                        sx={{
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          overflow: "hidden",
                          maxWidth: "fit-content",
                        }}
                      >
                        <SketchPicker
                          color={newLabel.color}
                          onChangeComplete={(color) =>
                            setNewLabel((prev) => ({
                              ...prev,
                              color: color.hex,
                            }))
                          }
                        />
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddNewLabel}
                      sx={{
                        alignSelf: "center",
                        backgroundColor: newLabel.color,
                        ":hover": {
                          backgroundColor: newLabel.color,
                          opacity: 0.9,
                        },
                      }}
                    >
                      {t("add_new_label")}
                    </Button>
                  </Box>
                </Box>
              )}
            </FormControl>
          )}
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6" sx={{ mb: 3 }}>
            {t("chat_information")}
          </Typography>
          <Divider sx={{ my: 1, mb: 3 }} />

          {/* Render metadata table or other details */}

          <Box display="flex" alignItems="center" sx={{ mb: 3 }}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              noWrap
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                flexGrow: 1,
              }}
            >
              <b>{t("chat_created")}:</b> {formattedCreatedAt}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" sx={{ mb: 3 }}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              noWrap
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                flexGrow: 1,
              }}
            >
              <b>{t("chat_updated")}:</b> {formattedUpdatedAt}
            </Typography>
          </Box>
          <Box
            mt={1}
            style={{ maxHeight: "50vh", overflowY: "auto" }}
            sx={{ mb: 3 }}
          >
            {renderMetadataTable(metadata)}
          </Box>

          {hasPrivilege("backoffice_chat", "edit") && (
            <>
              <Button
                variant="contained"
                color="warning"
                onClick={() => setIsCloseDialogOpen(true)}
                fullWidth
                disabled={!selectedChat || closingChat}
                sx={{ mb: 2 }}
              >
                {t("close_chat")}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => setIsRestartDialogOpen(true)}
                fullWidth
                disabled={!selectedChat || closingChat}
                sx={{ mb: 2 }}
              >
                {t("restart_chat")}
              </Button>

              {renderCloseChatDialog()}
              {renderRestartChatDialog()}
            </>
          )}
        </Sidebar>
      )}
    </Grid>
  );
};

export default ChatInformation;
