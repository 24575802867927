import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  Checkbox,
  Grid,
  Typography,
  TextField,
  Switch,
  useMediaQuery,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

import { getData, postData, patchData, deleteData } from "../../services/Api";
import { menuItems } from "../../utils/Config";

import {
  StyledDialogButton,
  CancelButton,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
} from "./UsersStyles";

import ManuarTable from "../../components/ManuarTable";
import { renderPrivilegeIcons } from "./utils";

const UsersTable = ({ setDisplayMessage }) => {
  const { t } = useTranslation("users"); // Initialize translation
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Check if the device is mobile
  const [selectedUser, setSelectedUser] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openTempPasswordDialog, setOpenTempPasswordDialog] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [newUser, setNewUser] = useState({ user_id: "", privilege: {} });
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToReset, setUserToReset] = useState(null);
  const [tempPassword, setTempPassword] = useState(""); // To hold the temporary password
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getData("/backoffice/users");
        setUsers(response.users);
      } catch (error) {
        console.error(t("error_loading_users"), error);
        setDisplayMessage(t("error_loading_users"));
      }
    };
    fetchUsers();
  }, [t]);

  const handleOpenEditDialog = (row) => {
    const user = users.filter((key) => key["user_id"] == row[0])[0];
    setSelectedUser(user);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setSelectedUser(null);
    setOpenEditDialog(false);
  };

  const handleSaveChanges = async () => {
    try {
      await patchData(`/backoffice/users/${selectedUser.user_id}`, {
        privilege: selectedUser.privilege,
      });
      setUsers(
        users.map((u) =>
          u.user_id === selectedUser.user_id ? selectedUser : u
        )
      );
      handleCloseEditDialog();
    } catch (error) {
      console.error(t("error_saving_changes"), error);
    }
  };

  const handlePrivilegeChange = (section, privilege, checked) => {
    const updatedPrivileges = checked
      ? [...(selectedUser.privilege[section] || []), privilege]
      : (selectedUser.privilege[section] || []).filter((p) => p !== privilege);

    setSelectedUser({
      ...selectedUser,
      privilege: {
        ...selectedUser.privilege,
        [section]: updatedPrivileges,
      },
    });
  };

  const handleSelectAllPrivileges = (section, checked) => {
    const privileges = checked ? ["view", "create", "edit", "remove"] : [];
    setSelectedUser({
      ...selectedUser,
      privilege: {
        ...selectedUser.privilege,
        [section]: privileges,
      },
    });
  };

  const handleOpenDeleteDialog = (user) => {
    setUserToDelete(user);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setUserToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteUser = async () => {
    if (userToDelete) {
      try {
        await deleteData(`/backoffice/users/${userToDelete.user_id}`);
        setUsers(users.filter((user) => user.user_id !== userToDelete.user_id));
        handleCloseDeleteDialog();
      } catch (error) {
        console.error(t("error_deleting_user"), error);
      }
    }
  };

  const handleCloseAddDialog = () => {
    setNewUser({ user_id: "", privilege: {} });
    setOpenAddDialog(false);
  };

  const handleAddPrivilegeChange = (section, privilege, checked) => {
    const updatedPrivileges = checked
      ? [...(newUser.privilege[section] || []), privilege]
      : (newUser.privilege[section] || []).filter((p) => p !== privilege);

    setNewUser({
      ...newUser,
      privilege: {
        ...newUser.privilege,
        [section]: updatedPrivileges,
      },
    });
  };

  const handleSelectAllAddPrivileges = (section, checked) => {
    const privileges = checked ? ["view", "create", "edit", "remove"] : [];
    setNewUser({
      ...newUser,
      privilege: {
        ...newUser.privilege,
        [section]: privileges,
      },
    });
  };

  const handleSaveNewUser = async () => {
    try {
      newUser.user_id = newUser.user_id.trim();
      const response = await postData("/backoffice/users", newUser);
      setUsers([...users, response]);
      setTempPassword(response.temporary_password); // Store the temporary password
      setOpenTempPasswordDialog(true); // Open the temporary password dialog
      handleCloseAddDialog();
    } catch (error) {
      console.error(t("error_adding_user"), error);
    }
  };

  const handleOpenResetPasswordDialog = (user) => {
    setUserToReset(user);
    setOpenResetPasswordDialog(true);
  };

  const handleCloseResetPasswordDialog = () => {
    setUserToReset(null);
    setOpenResetPasswordDialog(false);
  };

  const [resetPassword, setResetPassword] = useState(""); // New state for password input

  const handleResetPassword = async () => {
    if (userToReset && resetPassword.length >= 6) {
      // Check password length
      try {
        const response = await postData(
          `/backoffice/users/${userToReset.user_id}/reset-password`,
          { password: resetPassword } // Send password in the request body
        );
        if ("temporary_password" in response) {
          setTempPassword(response.temporary_password);
          setOpenTempPasswordDialog(true);
        }
        handleCloseResetPasswordDialog();
      } catch (error) {
        console.error(t("error_resetting_password"), error);
      }
    } else {
      console.error(t("password_too_short")); // Optional: Log an error for short passwords
    }
  };

  const handleCloseTempPasswordDialog = () => {
    setOpenTempPasswordDialog(false);
  };

  const privilegeSections = [...menuItems];

  return (
    <ManuarTable
      header={[
        t("user_id"),
        ...privilegeSections.map((item) =>
          isMobile ? item.text.charAt(0) : t(item.text.toLowerCase())
        ),
      ]}
      content={
        users &&
        users.map((user) => [
          user.user_id,
          ...privilegeSections.map((item) =>
            renderPrivilegeIcons(user.privilege[item.section] || [], t)
          ),
        ])
      }
      actions={{
        add: {
          text: t("add_user"),
          event: () => setOpenAddDialog(true),
          icon: <AddIcon />,
        },
        edit: {
          text: t("edit_user"),
          event: handleOpenEditDialog,
          icon: <EditIcon />,
        },
        delete: {
          text: t("delete_user"),
          event: handleOpenDeleteDialog,
          icon: <DeleteIcon />,
          color: "delete",
        },
        password: {
          text: t("change_password_user"),
          event: handleOpenResetPasswordDialog,
          icon: <VpnKeyIcon />,
        },
      }}
    >
      {/* Edit User Privileges Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        maxWidth={isMobile ? "xs" : "sm"}
      >
        <StyledDialogTitle>{t("edit_user_privileges")}</StyledDialogTitle>
        <StyledDialogContent>
          <Grid container spacing={2}>
            {selectedUser &&
              privilegeSections.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={4} key={item.section}>
                  <Typography variant="subtitle1" sx={{ display: "inline" }}>
                    {t(item.text.toLowerCase())}
                  </Typography>
                  <Switch
                    checked={selectedUser.privilege[item.section]?.length === 4}
                    onChange={(e) =>
                      handleSelectAllPrivileges(item.section, e.target.checked)
                    }
                    color="primary"
                  />
                  {["view", "create", "edit", "remove"].map((privilege) => (
                    <div key={privilege}>
                      <Checkbox
                        checked={
                          selectedUser.privilege[item.section]?.includes(
                            privilege
                          ) || false
                        }
                        onChange={(e) =>
                          handlePrivilegeChange(
                            item.section,
                            privilege,
                            e.target.checked
                          )
                        }
                      />
                      <label>{t(privilege)}</label>
                    </div>
                  ))}
                </Grid>
              ))}
          </Grid>
        </StyledDialogContent>
        <StyledDialogActions>
          <CancelButton onClick={handleCloseEditDialog}>
            {t("cancel")}
          </CancelButton>
          <StyledDialogButton onClick={handleSaveChanges}>
            {t("save")}
          </StyledDialogButton>
        </StyledDialogActions>
      </Dialog>

      {/* Add User Dialog */}
      <Dialog
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        maxWidth={isMobile ? "xs" : "sm"}
      >
        <StyledDialogTitle>{t("add_new_user")}</StyledDialogTitle>
        <StyledDialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t("user_id")}
            type="email"
            fullWidth
            value={newUser.user_id}
            onChange={(e) =>
              setNewUser({ ...newUser, user_id: e.target.value })
            }
          />
          <Grid container spacing={2}>
            {privilegeSections.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={item.section}>
                <Typography variant="subtitle1" sx={{ display: "inline" }}>
                  {t(item.text.toLowerCase())}
                </Typography>
                <Switch
                  checked={newUser.privilege[item.section]?.length === 4}
                  onChange={(e) =>
                    handleSelectAllAddPrivileges(item.section, e.target.checked)
                  }
                  color="primary"
                />
                {["view", "create", "edit", "remove"].map((privilege) => (
                  <div key={privilege}>
                    <Checkbox
                      size="small"
                      checked={
                        newUser.privilege[item.section]?.includes(privilege) ||
                        false
                      }
                      onChange={(e) =>
                        handleAddPrivilegeChange(
                          item.section,
                          privilege,
                          e.target.checked
                        )
                      }
                    />
                    <label>{t(privilege)}</label>
                  </div>
                ))}
              </Grid>
            ))}
          </Grid>
        </StyledDialogContent>
        <StyledDialogActions>
          <CancelButton onClick={handleCloseAddDialog}>
            {t("cancel")}
          </CancelButton>
          <StyledDialogButton onClick={handleSaveNewUser}>
            {t("save")}
          </StyledDialogButton>
        </StyledDialogActions>
      </Dialog>

      {/* Temporary Password Dialog */}
      <Dialog
        open={openTempPasswordDialog}
        onClose={handleCloseTempPasswordDialog}
        aria-labelledby="temp-password-dialog-title"
        maxWidth={isMobile ? "xs" : "sm"}
      >
        <StyledDialogTitle id="temp-password-dialog-title">
          {t("user_created_password_reset_success")}
        </StyledDialogTitle>
        <StyledDialogContent>
          <Typography>{t("action_completed_successfully")}</Typography>
          <Typography variant="h6" color="primary" gutterBottom>
            {tempPassword}
          </Typography>
          <Typography>{t("advise_change_password")}</Typography>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledDialogButton onClick={handleCloseTempPasswordDialog}>
            {t("confirm")}
          </StyledDialogButton>
        </StyledDialogActions>
      </Dialog>

      {/* Reset Password Confirmation Dialog */}
      <Dialog
        open={openResetPasswordDialog}
        onClose={handleCloseResetPasswordDialog}
        aria-labelledby="reset-password-dialog-title"
        maxWidth={isMobile ? "xs" : "sm"}
      >
        <StyledDialogTitle id="reset-password-dialog-title">
          {t("reset_password_confirmation")}
        </StyledDialogTitle>
        <StyledDialogContent>
          <Typography>
            {t("confirm_reset_password", { user: userToReset?.user_id })}
          </Typography>
          <TextField
            margin="dense"
            label={t("new_password")}
            type="password"
            fullWidth
            value={resetPassword}
            onChange={(e) => setResetPassword(e.target.value)}
            helperText={t("password_min_length")} // Optional: Display a helper text
            error={resetPassword.length > 0 && resetPassword.length < 6} // Show error if length < 6
          />
        </StyledDialogContent>
        <StyledDialogActions>
          <CancelButton onClick={handleCloseResetPasswordDialog}>
            {t("cancel")}
          </CancelButton>
          <StyledDialogButton onClick={handleResetPassword}>
            {t("reset_password")}
          </StyledDialogButton>
        </StyledDialogActions>
      </Dialog>

      {/* Delete User Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        maxWidth={isMobile ? "xs" : "sm"}
      >
        <StyledDialogTitle id="delete-dialog-title">
          {t("confirm_delete")}
        </StyledDialogTitle>
        <StyledDialogContent>
          <Typography>
            {t("confirm_delete_user", { user: userToDelete?.user_id })}
          </Typography>
        </StyledDialogContent>
        <StyledDialogActions>
          <CancelButton onClick={handleCloseDeleteDialog}>
            {t("cancel")}
          </CancelButton>
          <StyledDialogButton onClick={handleDeleteUser}>
            {t("delete")}
          </StyledDialogButton>
        </StyledDialogActions>
      </Dialog>
    </ManuarTable>
  );
};

export default UsersTable;
