import { styled } from "@mui/material/styles";
import { Drawer, ListItemText, Box, ListItem } from "@mui/material";

// Drawer styling
export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowX: "hidden",
    transition: "transform 0.3s ease-in-out",
  },
}));

// Container for drawer content
export const DrawerContainer = styled(Box)(({ theme }) => ({
  overflow: "auto",
  padding: theme.spacing(2),
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between", // Add this for bottom alignment of the dropdown
}));

// ListItem text style
export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

// Logo container
export const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1),
}));

// Styled ListItem with conditional background color when selected
export const StyledListItem = styled(ListItem)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? theme.palette.grey[600] : "transparent",
  borderRadius: 7,
  "& span": {
    color: isSelected ? "rgba(255,255,255,0.9)" : "black",
  },
  "& svg": {
    color: isSelected ? "rgba(255,255,255,0.9) !important" : "black",
  },
  "&:hover": {
    backgroundColor: isSelected
      ? theme.palette.grey[700]
      : theme.palette.grey[300],
  },
  transition: "background-color 0.3s ease",
}));
