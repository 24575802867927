import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  styled,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search"; // Ícono de lupa

const TaskItem = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  marginBottom: "10px",
  backgroundColor: "#fff",
  boxShadow: theme.shadows[1],
  position: "relative",
  cursor: "grab",
  borderRadius: "8px", // Bordes redondeados para el TaskItem
}));

const ColorLabel = styled(Box)(({ color }) => ({
  width: "10px", // Más gruesa
  height: "100%",
  backgroundColor: color,
  position: "absolute",
  left: 0,
  top: 0,
  bottom: 0,
  borderTopLeftRadius: "8px",
  borderBottomLeftRadius: "8px",
}));

const TaskList = ({ tasks }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredTasks = tasks.filter((task) =>
    task.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {/* Campo de búsqueda con ícono de lupa */}
      <TextField
        fullWidth
        label="Buscar tareas"
        variant="outlined"
        margin="normal"
        value={searchTerm}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <Box>
        {/* Lista de tareas filtrada */}
        {filteredTasks.map((task) => (
          <TaskItem
            key={task.id}
            draggable
            onDragStart={(e) =>
              e.dataTransfer.setData("task", JSON.stringify(task))
            }
          >
            <ColorLabel color={task.color} />
            <Box ml={2}>
              {/* Margen izquierdo para separar el texto de la barra */}
              <Typography variant="subtitle1" fontWeight="bold">
                {task.name}
              </Typography>
              <Typography variant="body2">{task.description}</Typography>
            </Box>
          </TaskItem>
        ))}
      </Box>
    </Box>
  );
};

export default TaskList;
