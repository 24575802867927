import React, { useEffect, useState } from "react";
import NodeEditorWrapper from "../Diagram/NodeEditor";
import TaskList from "./TaskList";
import {
  Box,
  Typography,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import COLORS from "../../styles/colors";
import TaskDrawer from "../TaskDrawer";
import ListIcon from "@mui/icons-material/List";

const EditorContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: `calc(100vh - 80px)`,
  overflow: "hidden",
  paddingTop: theme.spacing(4),
  position: "relative",
}));

const TaskCreator = styled(Box)(({ theme, show }) => ({
  width: show ? "25%" : "0",
  padding: show ? "20px" : "0",
  backgroundColor: "#f8f8f8",
  borderRight: "1px solid #ddd",
  maxHeight: "100vh",
  overflowY: "auto",
  transition: "width 0.3s ease, padding 0.3s ease",

  [theme.breakpoints.down("md")]: {
    width: show ? "50%" : "0",
    padding: show ? "15px" : "0",
  },

  [theme.breakpoints.down("sm")]: {
    width: show ? "100%" : "0",
    padding: show ? "10px" : "0",
  },
}));

const NodeEditorContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  maxHeight: "100vh",
  overflow: "auto",
  paddingBottom: "20px",
  alignItems: "flex-end",
}));

const NodeEditorWrapperStyled = styled(NodeEditorWrapper)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const Editor = () => {
  const [showTaskCreator, setShowTaskCreator] = useState(true);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isSmallScreen) {
      setShowTaskCreator(false);
    }
  }, [isSmallScreen]);

  const taskTypes = [
    {
      id: 1,
      name: "ReturnMessage",
      description: "Esta tarea envía un mensaje predefinido al usuario",
      color: COLORS.ReturnMessage,
    },
    {
      id: 2,
      name: "LLMClassification",
      description:
        "Clasifica el texto del usuario según una instrucción y categorías",
      color: COLORS.LLMClassification,
    },
    {
      id: 3,
      name: "LLMGen",
      description: "Genera respuestas o resúmenes basados en el contexto",
      color: COLORS.LLMGen,
    },
    {
      id: 4,
      name: "RAG",
      description:
        "Utiliza almacenamiento vectorial para enriquecer las respuestas",
      color: COLORS.RAG,
    },
    {
      id: 5,
      name: "Checker",
      description:
        "Realiza validaciones específicas en los metadatos o el estado actual de la conversación",
      color: COLORS.Checker,
    },
    {
      id: 6,
      name: "Curl",
      description:
        "Realiza solicitudes HTTP parametrizadas (generalmente POST) con datos dinámicos",
      color: COLORS.Curl,
    },
    {
      id: 7,
      name: "ImageDetection",
      description:
        "Detecta objetos en imágenes usando métodos preentrenados y asigna metadatos en función de los resultados",
      color: COLORS.ImageDetection,
    },
    {
      id: 8,
      name: "GPTImageClassification",
      description:
        "Clasifica imágenes utilizando un modelo de lenguaje LLM compatible con entrada visual y textual",
      color: COLORS.GPTImageClassification,
    },
    {
      id: 9,
      name: "Retrieve",
      description:
        "Busca valores en una fuente de datos externa, como un archivo CSV o base de datos",
      color: COLORS.Retrieve,
    },
  ];

  const toggleTaskCreator = () => {
    setShowTaskCreator((prev) => !prev);
  };

  return (
    <EditorContainer>
      <TaskCreator show={showTaskCreator || !isSmallScreen}>
        <Typography variant="h6" gutterBottom>
          Crear Tareas
        </Typography>
        <TaskList tasks={taskTypes} />
      </TaskCreator>

      <NodeEditorContainer>
        <NodeEditorWrapperStyled />
      </NodeEditorContainer>

      {isSmallScreen && (
        <IconButton
          onClick={toggleTaskCreator}
          sx={{
            position: "absolute",
            bottom: 20,
            right: 20,
            backgroundColor: "#007BFF",
            color: "white",
            "&:hover": {
              backgroundColor: "#0056b3",
            },
          }}
        >
          <ListIcon />
        </IconButton>
      )}

      <TaskDrawer />
    </EditorContainer>
  );
};

export default Editor;
