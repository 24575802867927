import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ViewIcon from "@mui/icons-material/Visibility";

import { Typography, Box } from "@mui/material";

export const renderPrivilegeIcons = (privileges, t) => {
  if (!privileges || privileges.length === 0) return null;

  // Define the priority order
  const priority = { view: 1, edit: 2, create: 3 };

  // Determine the highest privilege based on priority
  let highestPrivilege = null;
  let highestPriority = -1;

  privileges.forEach((privilege) => {
    if (priority[privilege] > highestPriority) {
      highestPrivilege = privilege;
      highestPriority = priority[privilege];
    }
  });

  // Determine the corresponding icon
  let Icon;
  let text;
  switch (highestPrivilege) {
    case "view":
      Icon = ViewIcon;
      text = t("view");
      break;
    case "edit":
      Icon = EditIcon;
      text = t("edit");
      break;
    case "create":
      Icon = AddIcon;
      text = t("create");
      break;
    default:
      Icon = null;
      text = "";
  }

  // Return the icon with styled text if it exists
  return Icon ? (
    <Box alignItems="center" gap={1}>
      <Icon fontSize="small" sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
      <Typography
        variant="body2"
        sx={{
          color: "rgba(0, 0, 0, 0.87)",
          fontWeight: 500,
          lineHeight: 1.5,
        }}
      >
        {text}
      </Typography>
    </Box>
  ) : null;
};
