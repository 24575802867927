import React from "react";
import "../../styles/edges.css";
import { BezierEdge, EdgeText, useInternalNode } from "@xyflow/react";

export default function SelfConnectingEdge(props) {
  const sourceNode = useInternalNode(props.source);

  if (props.source !== props.target) {
    return <BezierEdge {...props} />;
  }
  const { sourceX, sourceY, targetX, targetY, markerEnd, label } = props;

  const nodeWidth = sourceNode.measured.width;
  const nodeHeight = sourceNode.measured.height;

  const radiusX = nodeWidth / 2 + 10;
  const radiusY = nodeHeight / 2 + 10;

  const edgePath = `M ${sourceX} ${
    sourceY - 10
  } A ${radiusX} ${radiusY} 0 1 1 ${targetX} ${targetY - 10}`;

  return (
    <>
      <path
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {label && (
        <EdgeText
          x={sourceX - 64}
          y={sourceY - 120}
          label={label}
          labelStyle={{ fill: "#000", fontSize: 12 }}
        />
      )}
    </>
  );
}
