import { postData } from "../services/Api";

const useClientData = () => {
  const postTasks = async (payload) => {
    return postData("/backoffice/clients/agent", {
      tasks: payload,
    });
  };

  return { postTasks };
};

export default useClientData;
