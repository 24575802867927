import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to add Authorization and Client headers
apiClient.interceptors.request.use(
  (config) => {
    config.headers["Client"] = "manuar";
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const user = JSON.parse(storedUser);
      if (user.tokens && user.tokens.AccessToken) {
        config.headers.Authorization = `Bearer ${user.tokens.AccessToken}`;
      }
      if (user.company) {
        config.headers["Company"] = user.company;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.warn(
        "401 Unauthorized - Removing user from localStorage and redirecting to base URL."
      );
      localStorage.removeItem("user");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

// GET Request
export const getData = async (url, params = {}) => {
  try {
    const response = await apiClient.get(url, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

// POST Request
export const postData = async (url, data, isFormData = false) => {
  try {
    const config = isFormData
      ? {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      : {};

    const response = await apiClient.post(url, data, config);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

// PATCH Request
export const patchData = async (url, data, isFormData = false) => {
  try {
    const config = isFormData
      ? {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      : {};

    const response = await apiClient.patch(url, data, config);
    return response.data;
  } catch (error) {
    console.error("Error patching data:", error);
    throw error;
  }
};

// DELETE Request
export const deleteData = async (url) => {
  try {
    const response = await apiClient.delete(url);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export default apiClient;
