import React, { useEffect, useState } from "react";
import { useTaskDrawer } from "../../../context/TaskDrawerContext";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

const StringArrayParam = ({
  name,
  defaultValue,
  taskParamValue,
  isSpecific,
}) => {
  const { editTaskParam } = useTaskDrawer();
  const [listParam, setListParam] = useState(defaultValue);

  useEffect(() => {
    if (taskParamValue === undefined) {
      editTaskParam(name, defaultValue, isSpecific, false);
    } else {
      setListParam(taskParamValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, defaultValue]);

  const handleAddRow = () => {
    const updatedList = [...listParam, ""];
    setListParam(updatedList);
    editTaskParam(name, updatedList, isSpecific);
  };

  const handleDeleteRow = (index) => {
    const updatedList = listParam.filter((_, i) => i !== index);
    setListParam(updatedList);
    editTaskParam(name, updatedList, isSpecific);
  };

  const handleChange = (index, value) => {
    const updatedList = listParam.map((item, i) =>
      i === index ? value : item
    );
    setListParam(updatedList);
    editTaskParam(name, updatedList, isSpecific);
  };

  return (
    <Box>
      <Typography>{name}</Typography>
      {listParam.map((item, index) => (
        <Box
          key={index}
          sx={{ display: "flex", gap: 1, alignItems: "center", mb: 1 }}
        >
          <TextField
            value={item}
            placeholder={`Ingrese un texto (Fila ${index + 1})`}
            fullWidth
            onChange={(e) => handleChange(index, e.target.value)}
          />
          <IconButton onClick={() => handleDeleteRow(index)}>
            <Delete />
          </IconButton>
        </Box>
      ))}
      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <IconButton onClick={handleAddRow}>
          <Add />
        </IconButton>
      </Box>
    </Box>
  );
};

export default StringArrayParam;
