import React from "react";
import { PARAM_TYPES } from "../../../context/taskParamsUtils";
import StringParam from "./StringParam";
import NumberParam from "./NumberParam";
import BooleanParam from "./BooleanParam";
import StringArrayParam from "./StringArrayParam";
import OptionsParam from "./OptionsParam";
import { useTaskDrawer } from "../../../context/TaskDrawerContext";

const Param = ({ param, isSpecific }) => {
  const { task } = useTaskDrawer();

  const getParamComponent = () => {
    switch (param.type) {
      case PARAM_TYPES.STRING:
        return StringParam;
      case PARAM_TYPES.NUMBER:
        return NumberParam;
      case PARAM_TYPES.BOOLEAN:
        return BooleanParam;
      case PARAM_TYPES.ARRAY_OF_STRINGS:
        return StringArrayParam;
      case PARAM_TYPES.OPTIONS:
        return OptionsParam;
      default:
        return StringParam;
    }
  };

  const ParamComponent = getParamComponent();

  const taskParamValue = isSpecific
    ? task["task_params"]
      ? task["task_params"][param.name]
      : undefined
    : task[param.name];

  return (
    <ParamComponent
      name={param.name}
      defaultValue={param.default}
      options={param.options}
      isMultiple={param.isMultiple}
      isSpecific={isSpecific}
      taskParamValue={taskParamValue}
      isJson={param.type === PARAM_TYPES.DICT}
      isMandatory={param.mandatory}
    />
  );
};

export default Param;
