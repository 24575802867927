import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const localizer = momentLocalizer(moment);

// Custom Week Header
const CustomWeekHeader = ({ date, localizer }) => (
  <Typography
    align="center"
    sx={{ fontWeight: "bold", textTransform: "capitalize" }}
  >
    {localizer.format(date, "dddd")} {/* Weekday only */}
  </Typography>
);

// Custom Day Header for Mobile
const CustomDayHeader = ({ label }) => (
  <Typography
    align="center"
    sx={{
      fontWeight: "bold",
      fontSize: "1.1rem",
      textTransform: "capitalize",
    }}
  >
    {label} {/* Only display weekday name */}
  </Typography>
);

const WorkingHoursCalendar = ({
  workingHours,
  setWorkingHours,
  setDisplayMessage,
}) => {
  const [events, setEvents] = useState([]); // Start with an empty array
  useEffect(() => {
    setEvents(
      workingHours.map((slot) => ({
        title: "Available",
        start: new Date(slot.start),
        end: new Date(slot.end),
      }))
    );
  }, [workingHours]); // Update events whenever workingHours changes

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    moment().startOf("day").toDate()
  );
  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleSelectSlot = ({ start, end }) => {
    if (start >= end) {
      setDisplayMessage(
        "Invalid time slot. End time must be after start time."
      );
      return;
    }

    const hasOverlap = events.some(
      (event) =>
        (start >= event.start && start < event.end) ||
        (end > event.start && end <= event.end) ||
        (start <= event.start && end >= event.end)
    );

    if (hasOverlap) {
      setDisplayMessage(
        "Time slots cannot overlap. Please choose a valid slot."
      );
      return;
    }

    const newEvent = { title: "Available", start, end };
    setEvents([...events, newEvent]);
    setWorkingHours([...workingHours, { start, end }]);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setOpenDialog(true);
  };

  const handleRemoveEvent = () => {
    setEvents(events.filter((e) => e !== selectedEvent));
    setWorkingHours(
      workingHours.filter(
        (slot) =>
          new Date(slot.start).getTime() !== selectedEvent.start.getTime() &&
          new Date(slot.end).getTime() !== selectedEvent.end.getTime()
      )
    );
    setOpenDialog(false);
    setSelectedEvent(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEvent(null);
  };

  const navigateDay = (direction) => {
    const newDate = moment(currentDate)
      .add(direction === "next" ? 1 : -1, "days")
      .toDate();
    if (
      moment(newDate).isSameOrAfter(moment().startOf("week")) &&
      moment(newDate).isSameOrBefore(moment().endOf("week"))
    ) {
      setCurrentDate(newDate);
    }
  };

  return (
    <Box>
      {isMobile && (
        <Box display="flex" justifyContent="space-between" mb={2}>
          <IconButton onClick={() => navigateDay("prev")} color="primary">
            <ArrowBack />
          </IconButton>
          <Typography variant="h6">
            {moment(currentDate).format("dddd")}
          </Typography>
          <IconButton onClick={() => navigateDay("next")} color="primary">
            <ArrowForward />
          </IconButton>
        </Box>
      )}

      <Calendar
        localizer={localizer}
        events={events}
        selectable
        defaultView={isMobile ? "day" : "week"}
        views={isMobile ? ["day"] : ["week"]}
        date={currentDate}
        min={new Date(1970, 1, 1, 0)} // 12:00 AM
        max={new Date(1970, 1, 1, 23, 59)} // 11:59 PM
        step={30}
        timeslots={2}
        style={{ height: 600, borderRadius: "8px", border: "1px solid #ddd" }}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleSelectEvent}
        onNavigate={() => {}} // Disable built-in navigation
        toolbar={false}
        eventPropGetter={() => ({
          style: {
            backgroundColor: "#3f51b5",
            color: "#fff",
            borderRadius: "4px",
            border: "none",
          },
        })}
        components={{
          header: isMobile ? CustomDayHeader : CustomWeekHeader, // Handle both headers
        }}
      />

      {/* Confirmation Dialog for Removing Event */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Remove Time Slot</DialogTitle>
        <Typography sx={{ padding: "0 20px" }}>
          Are you sure you want to remove this time slot?
        </Typography>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRemoveEvent} color="error">
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WorkingHoursCalendar;
