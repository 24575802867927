import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  Checkbox,
  Grid,
  Typography,
  Switch,
  useMediaQuery,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ViewIcon from "@mui/icons-material/Visibility";

import { getData, postData, patchData, deleteData } from "../../services/Api";
import { menuItems } from "../../utils/Config";

import {
  StyledDialogButton,
  CancelButton,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
} from "./UsersStyles";

import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import Copy Icon
import { Tooltip, IconButton } from "@mui/material"; // Import Tooltip and IconButton

import ManuarTable from "../../components/ManuarTable";
import { renderPrivilegeIcons } from "./utils";

const ApiKeysTable = ({ setDisplayMessage }) => {
  const { t } = useTranslation("users"); // Initialize translation
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Check if the device is mobile
  const [apiKeys, setApiKeys] = useState([]);
  const [openApiKeyDialog, setOpenApiKeyDialog] = useState(false);
  const [apiKeyDialog, setApiKeyDialog] = useState({
    open: false,
    apiKey: "",
    apiSecret: "",
  });
  const [openEditApiKeyDialog, setOpenEditApiKeyDialog] = useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState(null);
  const [newPermissions, setNewPermissions] = useState({});
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setDisplayMessage(t("copied_to_clipboard"), "success");
    });
  };

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const response = await getData("/backoffice/api-keys");
        setApiKeys(response);
      } catch (error) {
        console.error(t("error_loading_api_keys"), error);
        setDisplayMessage(t("error_loading_api_keys"));
      }
    };

    fetchApiKeys();
  }, [t]);

  const handleCreateApiKey = async () => {
    try {
      const response = await postData("/backoffice/api-keys", {
        permissions: newPermissions,
      });

      setApiKeys([...apiKeys, response]);

      // Show the API Key and Secret in a new dialog
      setApiKeyDialog({
        open: true,
        apiKey: response.api_key,
        apiSecret: response.api_secret,
      });

      setOpenApiKeyDialog(false);
    } catch (error) {
      console.error(t("error_creating_api_key"), error.message);
      setDisplayMessage(t("error_creating_api_key"));
    }
  };

  const handleCloseApiKeyDialog = () => {
    setApiKeyDialog({
      open: false,
      apiKey: "",
      apiSecret: "",
    });
  };

  const handleOpenDeleteConfirmationDialog = (row) => {
    setSelectedApiKey(row[0]);
    setOpenDeleteConfirmationDialog(true);
  };

  const handleCloseDeleteConfirmationDialog = () => {
    setSelectedApiKey(null);
    setOpenDeleteConfirmationDialog(false);
  };

  const handleDeleteApiKey = async () => {
    if (selectedApiKey) {
      try {
        await deleteData(`/backoffice/api-keys/${selectedApiKey}`);
        setApiKeys(apiKeys.filter((key) => key.api_key !== selectedApiKey));
        handleCloseDeleteConfirmationDialog();
      } catch (error) {
        console.error(t("error_deleting_api_key"), error);
        setDisplayMessage(t("error_deleting_api_key"));
      }
    }
  };

  const handleOpenEditApiKeyDialog = (row) => {
    const apiKey = apiKeys.filter((key) => key["api_key"] == row[0])[0];
    setSelectedApiKey(apiKey);
    setNewPermissions(apiKey.permissions || {});
    setOpenEditApiKeyDialog(true);
  };

  const handleCloseEditApiKeyDialog = () => {
    setSelectedApiKey(null);
    setNewPermissions({});
    setOpenEditApiKeyDialog(false);
  };

  const handleSaveApiKeyChanges = async () => {
    try {
      const payload = {
        permissions: newPermissions,
      };

      const response = await patchData(
        `/backoffice/api-keys/${selectedApiKey.api_key}`,
        payload
      );

      setApiKeys(
        apiKeys.map((key) =>
          key.api_key === selectedApiKey.api_key ? response : key
        )
      );

      // Show the API Key and Secret in a new dialog if a new secret is generated
      if (response.new_secret) {
        setApiKeyDialog({
          open: true,
          apiKey: response.api_key,
          apiSecret: response.new_secret,
        });
      }

      handleCloseEditApiKeyDialog();
    } catch (error) {
      console.error(t("error_saving_api_key_changes"), error);
      setDisplayMessage(t("error_saving_api_key_changes"));
    }
  };

  const handleApiKeyPermissionChange = (section, privilege, checked) => {
    const updatedSectionPermissions = checked
      ? [...(newPermissions[section] || []), privilege]
      : (newPermissions[section] || []).filter((p) => p !== privilege);

    setNewPermissions({
      ...newPermissions,
      [section]: updatedSectionPermissions,
    });
  };

  const handleSelectAllApiKeyPermissions = (section, checked) => {
    const allPrivileges = ["view", "create", "edit", "remove"];
    setNewPermissions({
      ...newPermissions,
      [section]: checked ? allPrivileges : [],
    });
  };

  const privilegeSections = [...menuItems];

  return (
    <ManuarTable
      header={[
        t("api_key"),
        ...privilegeSections.map((item) =>
          isMobile ? item.text.charAt(0) : t(item.text.toLowerCase())
        ),
      ]}
      content={
        apiKeys &&
        apiKeys.map((key) => [
          key.api_key,
          ...privilegeSections.map((item) =>
            renderPrivilegeIcons(key.permissions[item.section] || [], t)
          ),
        ])
      }
      actions={{
        add: {
          text: t("create_api_key"),
          event: () => setOpenApiKeyDialog(true),
          icon: <AddIcon />,
        },
        edit: {
          text: t("edit"),
          event: handleOpenEditApiKeyDialog,
          icon: <EditIcon />,
        },
        delete: {
          text: t("delete"),
          event: handleOpenDeleteConfirmationDialog,
          icon: <DeleteIcon />,
          color: "delete",
        },
      }}
    >
      {/* Create API Key Dialog */}
      <Dialog
        open={openApiKeyDialog}
        onClose={() => setOpenApiKeyDialog(false)}
        maxWidth={isMobile ? "xs" : "sm"}
      >
        <StyledDialogTitle>{t("create_api_key")}</StyledDialogTitle>
        <StyledDialogContent>
          <Grid container spacing={2}>
            {menuItems.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={item.section}>
                <Typography variant="subtitle1" sx={{ display: "inline" }}>
                  {t(item.text.toLowerCase())}
                </Typography>
                <Switch
                  checked={newPermissions[item.section]?.length === 4}
                  onChange={(e) =>
                    handleSelectAllApiKeyPermissions(
                      item.section,
                      e.target.checked
                    )
                  }
                  color="primary"
                />
                {["view", "create", "edit", "remove"].map((privilege) => (
                  <div key={privilege}>
                    <Checkbox
                      size="small"
                      checked={
                        newPermissions[item.section]?.includes(privilege) ||
                        false
                      }
                      onChange={(e) =>
                        handleApiKeyPermissionChange(
                          item.section,
                          privilege,
                          e.target.checked
                        )
                      }
                    />
                    <label>{t(privilege)}</label>
                  </div>
                ))}
              </Grid>
            ))}
          </Grid>
        </StyledDialogContent>
        <StyledDialogActions>
          <CancelButton onClick={() => setOpenApiKeyDialog(false)}>
            {t("cancel")}
          </CancelButton>
          <StyledDialogButton onClick={handleCreateApiKey}>
            {t("save")}
          </StyledDialogButton>
        </StyledDialogActions>
      </Dialog>

      {/* Edit API Key Dialog */}
      <Dialog
        open={openEditApiKeyDialog}
        onClose={handleCloseEditApiKeyDialog}
        maxWidth={isMobile ? "xs" : "sm"}
      >
        <StyledDialogTitle>{t("edit_api_key")}</StyledDialogTitle>
        <StyledDialogContent>
          <Grid container spacing={2}>
            {menuItems.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={item.section}>
                <Typography variant="subtitle1" sx={{ display: "inline" }}>
                  {t(item.text.toLowerCase())}
                </Typography>
                <Switch
                  checked={(newPermissions[item.section] || []).length === 4}
                  onChange={(e) =>
                    handleSelectAllApiKeyPermissions(
                      item.section,
                      e.target.checked
                    )
                  }
                  color="primary"
                />
                {["view", "create", "edit", "remove"].map((privilege) => (
                  <div key={privilege}>
                    <Checkbox
                      size="small"
                      checked={
                        newPermissions[item.section]?.includes(privilege) ||
                        false
                      }
                      onChange={(e) =>
                        handleApiKeyPermissionChange(
                          item.section,
                          privilege,
                          e.target.checked
                        )
                      }
                    />
                    <label>{t(privilege)}</label>
                  </div>
                ))}
              </Grid>
            ))}
          </Grid>
        </StyledDialogContent>
        <StyledDialogActions>
          <CancelButton onClick={handleCloseEditApiKeyDialog}>
            {t("cancel")}
          </CancelButton>
          <StyledDialogButton onClick={handleSaveApiKeyChanges}>
            {t("save")}
          </StyledDialogButton>
        </StyledDialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteConfirmationDialog}
        onClose={handleCloseDeleteConfirmationDialog}
        maxWidth={isMobile ? "xs" : "sm"}
      >
        <StyledDialogTitle>{t("confirm_delete")}</StyledDialogTitle>
        <StyledDialogContent>
          <Typography>
            {t("confirm_delete_api_key", {
              apiKey: selectedApiKey,
            })}
          </Typography>
        </StyledDialogContent>
        <StyledDialogActions>
          <CancelButton onClick={handleCloseDeleteConfirmationDialog}>
            {t("cancel")}
          </CancelButton>
          <StyledDialogButton onClick={handleDeleteApiKey}>
            {t("delete")}
          </StyledDialogButton>
        </StyledDialogActions>
      </Dialog>

      {/* New API Key or Secret Dialog */}
      <Dialog
        open={apiKeyDialog.open}
        onClose={handleCloseApiKeyDialog}
        maxWidth={isMobile ? "xs" : "sm"}
      >
        <StyledDialogTitle>{t("api_key_created")}</StyledDialogTitle>
        <StyledDialogContent>
          <Typography>{t("api_key_details")}</Typography>

          <Box mt={2} display="flex" alignItems="center">
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
              onClick={() => copyToClipboard(apiKeyDialog.apiKey)}
            >
              {t("api_key")}:
            </Typography>
            <Tooltip title={t("copy")}>
              <IconButton onClick={() => copyToClipboard(apiKeyDialog.apiKey)}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography
            variant="body2"
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={() => copyToClipboard(apiKeyDialog.apiKey)} // Copy on text click
          >
            {apiKeyDialog.apiKey}
          </Typography>

          <Box mt={2} display="flex" alignItems="center">
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ cursor: "pointer" }}
              onClick={() => copyToClipboard(apiKeyDialog.apiSecret)} // Make text clickable
            >
              {t("api_secret")}:
            </Typography>
            <Tooltip title={t("copy")}>
              <IconButton
                onClick={() => copyToClipboard(apiKeyDialog.apiSecret)}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography
            variant="body2"
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={() => copyToClipboard(apiKeyDialog.apiSecret)} // Copy on text click
          >
            {apiKeyDialog.apiSecret}
          </Typography>

          <Typography mt={2} color="error">
            {t("api_key_warning")}
          </Typography>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledDialogButton onClick={handleCloseApiKeyDialog}>
            {t("close")}
          </StyledDialogButton>
        </StyledDialogActions>
      </Dialog>
    </ManuarTable>
  );
};

export default ApiKeysTable;
