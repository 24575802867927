import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Paper,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import SendIcon from "@mui/icons-material/Send";
import { TextareaAutosize } from "@mui/material";
import { useTranslation } from "react-i18next";

const ChatInput = ({
  selectedChat,
  sendingMessage,
  handleSendMessage,
  newMessage,
  setNewMessage,
  handleFileUpload,
  handlePhotoUpload,
  handleAudioUpload,
}) => {
  const { t } = useTranslation("chats");
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const [timeLeft, setTimeLeft] = useState(30);
  const [audioHistory, setAudioHistory] = useState([]);
  const [uploading, setUploading] = useState(false); // New state for tracking uploads
  const canvasRef = useRef(null);
  const audioContextClosed = useRef(false);

  useEffect(() => {
    if (!canvasRef.current) return;
    if (!audioHistory) return;
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    const width = canvas.width;
    const height = canvas.height;
    canvasCtx.clearRect(0, 0, width, height);
    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = "#0033a0";

    canvasCtx.beginPath();
    const sliceWidth = (width * 1.0) / audioHistory.length;
    let x = 0;
    for (let i = 0; i < audioHistory.length; i++) {
      const v = audioHistory[i] / 128.0;
      const y = (v * height) / 2;
      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }
      x += sliceWidth;
    }
    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();
  }, [audioHistory]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploading(true);
      await handleFileUpload(file);
      setUploading(false);
    }
  };

  const handlePhotoChange = async (event) => {
    const photo = event.target.files[0];
    if (photo) {
      setUploading(true);
      await handlePhotoUpload(photo);
      setUploading(false);
    }
  };

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const analyserNode = audioCtx.createAnalyser();
      analyserNode.fftSize = 2048;
      const source = audioCtx.createMediaStreamSource(stream);
      source.connect(analyserNode);
      setAudioContext(audioCtx);
      setAudioHistory([]);
      audioContextClosed.current = false;

      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      let recorderIsPlaying = true;

      recorder.ondataavailable = async (event) => {
        const audioBlob = event.data;
        recorderIsPlaying = false;
        setUploading(true);
        await handleAudioUpload(audioBlob);
        setUploading(false);
      };

      recorder.start();
      setRecording(true);

      let timer = 30;
      setTimeLeft(timer);
      const timeoutTimer = () => {
        timer -= 1;
        setTimeLeft(timer);
        if (timer <= 0 || !recorderIsPlaying) {
          recorder.stop();
          setRecording(false);
        } else {
          setTimeout(timeoutTimer, 1000);
        }
      };
      timeoutTimer();

      const draw = () => {
        if (!canvasRef.current || !analyserNode) return;
        const canvas = canvasRef.current;
        const width = canvas.width;
        const dataArray = new Uint8Array(analyserNode.frequencyBinCount);
        analyserNode.getByteTimeDomainData(dataArray);

        setAudioHistory((prevHistory) => {
          const newHistory = [...prevHistory, ...Array.from(dataArray)];
          if (newHistory.length > width) {
            newHistory.splice(newHistory.length - width, newHistory.length);
          }
          return [...newHistory];
        });
        requestAnimationFrame(draw);
      };
      draw();
    } catch (err) {
      console.error("Error starting audio recording:", err);
    }
  };

  const handleStopRecording = () => {
    mediaRecorder.stop();
    setRecording(false);
    if (audioContext && !audioContextClosed.current) {
      audioContext.close();
      audioContextClosed.current = true;
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      style={{ height: "10%", marginTop: "1vh" }}
    >
      <Box
        component={Paper}
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          flexShrink: 0,
          position: "relative", // Added for overlay positioning
        }}
      >
        {uploading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!recording && (
          <TextareaAutosize
            minRows={1}
            maxRows={4}
            placeholder={t("type_a_message_placeholder")}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter" && !sendingMessage) {
                handleSendMessage();
              }
            }}
            disabled={!selectedChat || sendingMessage || uploading} // Disable input while uploading
            style={{
              width: "100%",
              padding: "8px",
              resize: "none",
              borderRadius: "20px",
              fontFamily: "'Roboto', sans-serif",
              border: "0px solid #ccc",
            }}
          />
        )}
        {recording && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <canvas
              ref={canvasRef}
              width="400"
              height="50"
              style={{
                width: "90%",
                height: "50px",
                backgroundColor: "#f2f2f2",
              }}
            />
            <Typography variant="h6" color="textSecondary" sx={{ ml: 2 }}>
              {timeLeft}s
            </Typography>
          </Box>
        )}
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          id="photo-upload"
          onChange={handlePhotoChange}
        />
        <label htmlFor="photo-upload">
          <IconButton
            sx={{ p: "10px" }}
            aria-label="upload photo"
            disabled={!selectedChat || uploading} // Disable button while uploading
            component="span"
          >
            <PhotoCameraIcon />
          </IconButton>
        </label>

        <input
          type="file"
          style={{ display: "none" }}
          id="file-upload"
          onChange={handleFileChange}
        />
        <label htmlFor="file-upload">
          <IconButton
            sx={{ p: "10px" }}
            aria-label="upload file"
            disabled={!selectedChat || uploading} // Disable button while uploading
            component="span"
          >
            <AttachFileIcon />
          </IconButton>
        </label>

        <IconButton
          sx={{ p: "10px" }}
          aria-label="record audio"
          onClick={recording ? handleStopRecording : handleStartRecording}
          disabled={!selectedChat || uploading} // Disable button while uploading
        >
          {recording ? <StopIcon /> : <MicIcon />}
        </IconButton>

        {!recording && (
          <IconButton
            sx={{ p: "10px" }}
            aria-label="send message"
            onClick={handleSendMessage}
            disabled={!selectedChat || sendingMessage || uploading} // Disable button while uploading
          >
            <SendIcon />
          </IconButton>
        )}
      </Box>
    </Grid>
  );
};

export default ChatInput;
