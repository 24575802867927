import React, { useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTasks } from "../../../context/TasksContext";
import { useTaskDrawer } from "../../../context/TaskDrawerContext";
import { comparisons } from "../../../context/taskParamsUtils";

const DISABLE_VALUES = "DISABLE_VALUES";
const ENABLE_SINGLE_VALUE = "ENABLE_SINGLE_VALUE";
const ENABLE_MULTIPLE_VALUES = "ENABLE_MULTIPLE_VALUES";

const getInputBehavior = (comparison) => {
  switch (comparison) {
    case "exists":
    case "not_exist":
      return DISABLE_VALUES;
    case "equal":
    case "not_equal":
    case "startswith":
      return ENABLE_SINGLE_VALUE;
    case "in":
    case "not_in":
      return ENABLE_MULTIPLE_VALUES;
    default:
      return DISABLE_VALUES;
  }
};

const ConnectionsTab = () => {
  const { allTasks } = useTasks();
  const { editTaskParam, nextTask, categoryNextTasks, newCategoryNextTask } =
    useTaskDrawer();

  useEffect(() => {
    if (newCategoryNextTask) {
      editTaskParam("category_next_task", [
        ...categoryNextTasks,
        {
          comparison: "",
          metadata_key: "",
          next_task: newCategoryNextTask,
          value: [""],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addConnection = () => {
    editTaskParam("category_next_task", [
      ...categoryNextTasks,
      {
        comparison: "",
        metadata_key: "",
        next_task: "",
        value: [""],
      },
    ]);
  };

  const deleteConnection = (indexToDelete) => {
    const updatedNextTasks = categoryNextTasks.filter(
      (_, index) => index !== indexToDelete
    );
    editTaskParam("category_next_task", updatedNextTasks);
  };

  const handleChangeNextTask = (taskId) => {
    editTaskParam("next_task", taskId);
  };

  const updateConnection = (index, field, value) => {
    const updatedNextTasks = [...categoryNextTasks];
    updatedNextTasks[index][field] = value;
    editTaskParam("category_next_task", updatedNextTasks);
  };

  const addValue = (index) => {
    const updatedNextTasks = [...categoryNextTasks];
    updatedNextTasks[index].value.push("");
    editTaskParam("category_next_task", updatedNextTasks);
  };

  const removeValue = (index, valueIndex) => {
    const updatedNextTasks = [...categoryNextTasks];
    updatedNextTasks[index].value.splice(valueIndex, 1);
    editTaskParam("category_next_task", updatedNextTasks);
  };

  const updateValue = (index, valueIndex, value) => {
    const updatedNextTasks = [...categoryNextTasks];
    updatedNextTasks[index].value[valueIndex] = value;
    editTaskParam("category_next_task", updatedNextTasks);
  };

  return (
    <Box>
      <Typography variant="body1" mb={2}>
        Configuración de Conexiones
      </Typography>

      <Box mb={2}>
        <Typography>Siguiente Tarea</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Siguiente Tarea</InputLabel>
          <Select
            value={nextTask}
            onChange={(e) => handleChangeNextTask(e.target.value)}
          >
            <MenuItem value="">Sin tarea</MenuItem>
            {allTasks.map((task) => (
              <MenuItem key={task.id} value={task.id}>
                {task.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box display="flex" alignItems="center" gap={1} mb={2}>
        <Typography>category_next_task</Typography>
        <IconButton onClick={addConnection} variant="contained" size="large">
          <Add />
        </IconButton>
      </Box>

      {categoryNextTasks.map((connection, index) => {
        const inputBehavior = getInputBehavior(connection.comparison);

        return (
          <Accordion
            key={index}
            sx={{
              mb: 2,
              border:
                newCategoryNextTask === connection.next_task
                  ? "2px solid #1976d2"
                  : "1px solid #e0e0e0",
              boxShadow:
                newCategoryNextTask === connection.next_task
                  ? "0 0 10px rgba(25, 118, 210, 0.5)"
                  : "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                ".MuiAccordionSummary-content": {
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                },
              }}
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteConnection(index);
                }}
                sx={{
                  mr: 1,
                  "&:hover": {
                    color: "error.main",
                  },
                }}
              >
                <Delete />
              </IconButton>
              <Typography>Conexión condicional #{index + 1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl fullWidth margin="normal">
                <InputLabel>Siguiente Tarea</InputLabel>
                <Select
                  value={connection.next_task}
                  onChange={(e) =>
                    updateConnection(index, "next_task", e.target.value)
                  }
                >
                  {allTasks.map((task) => (
                    <MenuItem key={task.id} value={task.id}>
                      {task.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Metadata Key"
                fullWidth
                margin="normal"
                value={connection.metadata_key}
                onChange={(e) =>
                  updateConnection(index, "metadata_key", e.target.value)
                }
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Tipo de comparación</InputLabel>
                <Select
                  value={connection.comparison}
                  onChange={(e) =>
                    updateConnection(index, "comparison", e.target.value)
                  }
                >
                  {comparisons.map((comparison) => (
                    <MenuItem key={comparison.value} value={comparison.value}>
                      {comparison.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box>
                {inputBehavior !== DISABLE_VALUES && (
                  <>
                    <Typography>Values</Typography>
                    {inputBehavior === ENABLE_SINGLE_VALUE && (
                      <TextField
                        fullWidth
                        margin="normal"
                        value={connection.value[0]}
                        onChange={(e) => updateValue(index, 0, e.target.value)}
                      />
                    )}
                    {inputBehavior === ENABLE_MULTIPLE_VALUES &&
                      connection.value.map((val, valIndex) => (
                        <Box
                          key={valIndex}
                          display="flex"
                          alignItems="center"
                          gap={1}
                        >
                          <TextField
                            fullWidth
                            margin="normal"
                            value={val}
                            onChange={(e) =>
                              updateValue(index, valIndex, e.target.value)
                            }
                          />
                          <IconButton
                            onClick={() => removeValue(index, valIndex)}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      ))}
                    {inputBehavior === ENABLE_MULTIPLE_VALUES && (
                      <IconButton onClick={() => addValue(index)}>
                        <Add />
                      </IconButton>
                    )}
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default ConnectionsTab;
