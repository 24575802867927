import React, { useEffect, useState } from "react";
import { useTaskDrawer } from "../../../context/TaskDrawerContext";
import { Box, TextField, Typography } from "@mui/material";
import { jsonToString } from "../../../utils/JsonUtils";
import { debounce } from "lodash";

const StringParam = ({
  name,
  defaultValue,
  taskParamValue,
  isSpecific,
  isJson,
  isMandatory,
}) => {
  const { editTaskParam } = useTaskDrawer();
  const [paramValue, setParamValue] = useState(defaultValue);

  useEffect(() => {
    if (taskParamValue === undefined) {
      editTaskParam(name, defaultValue, isSpecific, false);
    } else {
      setParamValue(taskParamValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, defaultValue]);

  const handleChange = (value) => {
    setParamValue(value);
    debouncedEditTaskParam(value);
  };

  const debouncedEditTaskParam = debounce((value) => {
    editTaskParam(name, value, isSpecific);
  }, 500);

  return (
    <Box>
      <Typography>{name}</Typography>
      <TextField
        value={isJson ? jsonToString(paramValue) : paramValue}
        placeholder={`Ingrese un texto para ${name}`}
        fullWidth
        onChange={(e) => handleChange(e.target.value)}
        error={isMandatory && !paramValue} // Marca el campo en rojo si está vacío
        helperText={
          isMandatory && !paramValue ? "Este campo es obligatorio." : ""
        }
      />
    </Box>
  );
};

export default StringParam;
