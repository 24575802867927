import { Box, Typography } from "@mui/material";
import React from "react";
import { generalParams } from "../../../context/taskParamsUtils";
import Param from "../Params/Param";

const GeneralTab = () => {
  return (
    <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
      <Typography variant="body1" mb={2}>
        Configuración General
      </Typography>
      {generalParams.map((param) => (
        <Param param={param} />
      ))}
    </Box>
  );
};
export default GeneralTab;
