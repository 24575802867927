import React, { createContext, useContext, useState, useCallback } from "react";
import {
  Modal,
  Stack,
  Typography,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalConfig, setModalConfig] = useState({
    open: false,
    title: "",
    description: "",
    explanation: "",
    radioButtons: null,
    selectedRadioValue: null,
    onConfirmation: null,
  });

  const openModal = useCallback(
    ({ title, description, explanation, radioButtons, onConfirmation }) => {
      if (!title || !description) {
        throw new Error(
          "El título y la descripción son obligatorios para abrir el modal."
        );
      }

      setModalConfig({
        open: true,
        title,
        description,
        explanation: explanation || "",
        radioButtons: radioButtons || null,
        selectedRadioValue: radioButtons?.[0]?.value || null,
        onConfirmation: onConfirmation || (() => {}),
      });
    },
    []
  );

  const closeModal = useCallback(() => {
    setModalConfig((prev) => ({ ...prev, open: false }));
  }, []);

  const handleConfirmation = () => {
    if (modalConfig.onConfirmation) {
      modalConfig.onConfirmation(modalConfig.selectedRadioValue);
    }
    closeModal();
  };

  const handleRadioChange = (event) => {
    setModalConfig((prev) => ({
      ...prev,
      selectedRadioValue: event.target.value,
    }));
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}

      <Modal
        open={modalConfig.open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
          direction="column"
          spacing={2}
        >
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {modalConfig.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalConfig.description}
          </Typography>
          {modalConfig.radioButtons && (
            <RadioGroup
              value={modalConfig.selectedRadioValue}
              onChange={handleRadioChange}
            >
              {modalConfig.radioButtons.map((button) => (
                <FormControlLabel
                  key={button.value}
                  value={button.value}
                  control={<Radio />}
                  label={button.label}
                />
              ))}
            </RadioGroup>
          )}
          {modalConfig.explanation && (
            <Typography variant="body2" color="GrayText">
              {modalConfig.explanation}
            </Typography>
          )}
          <Stack direction="row" spacing={1} justifyContent="end">
            <Button onClick={closeModal} variant="outlined">
              Cerrar
            </Button>
            <Button variant="contained" onClick={handleConfirmation}>
              Confirmar
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
