import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Handle, Position } from "@xyflow/react";
import React from "react";
import COLORS from "../../styles/colors";
import "../../styles/xy-theme.css";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import HubIcon from "@mui/icons-material/Hub";
import FlagIcon from "@mui/icons-material/Flag";
import { Tooltip } from "@mui/material";
import { useTaskDrawer } from "../../context/TaskDrawerContext";
import { useTasks } from "../../context/TasksContext";

const Node = ({ id, data }) => {
  const { openDrawer } = useTaskDrawer();
  const { getTask } = useTasks();

  const {
    label,
    taskType,
    description,
    start,
    end,
    orchestrator,
    metadataKey,
  } = data;

  const nodeColor = COLORS[taskType];
  const isSpecialNode = start || end || orchestrator;
  const tooltipText = start ? "Inicio" : end ? "Fin" : "Orquestador";

  return (
    <Box
      className="custom-node"
      onClick={() => openDrawer(getTask(id))}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        minWidth: 200,
        maxWidth: 384,
        border: "none",
        borderRadius: "8px",
        backgroundColor: "background.paper",
        boxShadow: 1,
        position: "relative",
        cursor: "pointer",
      }}
    >
      {isSpecialNode && (
        <Tooltip title={tooltipText} arrow placement="top">
          <Box
            sx={{
              backgroundColor: nodeColor,
              height: 32,
              width: 32,
              borderRadius: "50%",
              position: "absolute",
              top: -36,
              left: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {start && (
              <RocketLaunchIcon sx={{ color: "white" }} fontSize="small" />
            )}
            {orchestrator && (
              <HubIcon sx={{ color: "white" }} fontSize="small" />
            )}
            {end && <FlagIcon sx={{ color: "white" }} fontSize="small" />}
            <Box
              sx={{
                width: "2px",
                height: 8,
                position: "absolute",
                backgroundColor: nodeColor,
                bottom: -8,
              }}
            />
          </Box>
        </Tooltip>
      )}
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          width: "100%",
          height: 24,
          backgroundColor: nodeColor,
          display: "flex",
          justifyContent: "center",
          borderRadius: "8px 8px 0 0",
          padding: "0 12px",
        }}
      >
        <Typography variant="body1" color="white">
          {label || id}
        </Typography>
      </Box>

      {!orchestrator && (
        <Handle
          type="target"
          position={Position.Top}
          className="custom-handle"
          style={{ left: "50%", transform: "translateX(-50%)", top: -12 }}
        />
      )}

      <Box
        sx={{
          padding: 1,
          display: "flex",
          justifyContent: "start",
          alignItems: "start",
          width: "100%",
          gap: 1,
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            borderBottom: "1px solid silver",
            width: "100%",
            paddingBottom: 1,
          }}
        >
          <Typography variant="body2" color="GrayText">
            {description}
          </Typography>
        </Box>
        {metadataKey && !!metadataKey.length && (
          <Typography variant="caption" color="ActiveBorder">
            Metadata Key:{" "}
            <Typography variant="caption" fontStyle="italic" color="GrayText">
              {Array.isArray(metadataKey)
                ? metadataKey.join(", ")
                : metadataKey}
            </Typography>
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
          }}
        >
          <Typography variant="body2" color={nodeColor}>
            {taskType}
          </Typography>
        </Box>
      </Box>
      {!orchestrator && (
        <Handle
          type="source"
          position={Position.Bottom}
          className="custom-handle"
          style={{ left: "50%", transform: "translateX(-50%)", bottom: -12 }}
        />
      )}
    </Box>
  );
};

export default Node;