import React, { createContext, useState, useContext } from "react";
import { specificParams } from "./taskParamsUtils";

const TaskDrawerContext = createContext();

export const TaskDrawerProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [needSave, setNeedSave] = useState(false);
  const [task, setTask] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const [isCreating, setIsCreating] = useState(false);
  const [newCategoryNextTask, setNewCategoryNextTask] = useState();

  const categoryNextTaskValuesValid = (comparison, value) => {
    switch (comparison) {
      case "exists":
      case "not_exists":
        return true;
      case "in":
      case "not_in":
        return value.every((value) => !!value);
      case "equal":
      case "not_equal":
      case undefined:
      case "":
      case null:
      case "startswith":
        return (
          (Array.isArray(value) && value.length === 1 && value[0]) ||
          (!Array.isArray(value) && value)
        );
      default:
        return false;
    }
  };

  return (
    <TaskDrawerContext.Provider
      value={{
        open,
        task,
        setTask,
        tabIndex,
        setTabIndex,
        isCreating,
        setIsCreating,
        needSave,
        setNeedSave,
        newCategoryNextTask,
        setNewCategoryNextTask,

        openDrawer: (
          task,
          isCreating = false,
          tabIndex = 0,
          nextCategoryNextTaskId
        ) => {
          setTask(task);
          setOpen(true);
          setTabIndex(tabIndex);
          setIsCreating(isCreating);
          setNewCategoryNextTask(nextCategoryNextTaskId);
          setNeedSave(false);
        },

        closeDrawer: () => {
          setOpen(false);
          setNeedSave(false);
          setNewCategoryNextTask(null);
        },

        editTaskParam: (name, value, isSpecific = false, save = true) => {
          setTask((prevTask) => {
            if (isSpecific) {
              return {
                ...prevTask,
                task_params: {
                  ...prevTask.task_params,
                  [name]: value,
                },
              };
            } else {
              return {
                ...prevTask,
                [name]: value,
              };
            }
          });
          setNeedSave(save);
        },

        nextTask: task.next_task,

        categoryNextTasks: task.category_next_task || [],
        validateTask: () => {
          const taskName = task["task_name"];

          if (!specificParams[taskName]) {
            return false;
          }

          const params = specificParams[taskName];

          for (const param of params) {
            if (
              param.mandatory &&
              (task["task_params"]?.[param.name] === undefined ||
                task["task_params"][param.name] === null ||
                task["task_params"][param.name] === "")
            ) {
              return false;
            }
          }

          if (
            task.category_next_task &&
            task.category_next_task.some(
              (rule) =>
                !rule.next_task ||
                !rule.metadata_key ||
                !categoryNextTaskValuesValid(rule.comparison, rule.value)
            )
          )
            return false;

          return true;
        },
      }}
    >
      {children}
    </TaskDrawerContext.Provider>
  );
};

export const useTaskDrawer = () => useContext(TaskDrawerContext);
