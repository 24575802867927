import React, { useState, useEffect } from "react";
import style from "../../assets/css/markdown-styles.module.css";
import {
  Paper,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ChatHistory,
  ChatBubbleUser,
  ChatBubbleOperator,
} from "./ChatsViewStyles";

const decodeMessage = (message) => {
  return message
    .replace(/\n/g, "  \n")
    .replace(/\\n/g, "  \n")
    .replace(/\\u([\dA-F]{4})/gi, (_, code) =>
      String.fromCharCode(parseInt(code, 16))
    );
};

const ChatMessages = ({ messages, chatHistoryRef, isMobile }) => {
  const { t } = useTranslation("chats");
  const navigate = useNavigate();
  const [openContextDialog, setOpenContextDialog] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [expandedAccordion, setExpandedAccordion] = useState(0);
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const handleImageClick = (url) => {
    setFullscreenImage(url);
  };

  const handleCloseFullscreen = () => {
    setFullscreenImage(null);
  };

  useEffect(() => {
    if (chatHistoryRef?.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  const handleOpenContextDialog = (message) => {
    setSelectedMessage(message);
    setOpenContextDialog(true);
  };

  const handleCloseContextDialog = () => {
    setOpenContextDialog(false);
    setSelectedMessage(null);
  };

  const handleAccordionChange = (index) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? index : false);
  };

  return (
    <ChatHistory ref={chatHistoryRef} isMobile={isMobile}>
      {messages
        .filter((message) => message.sender !== "ai" || message.complete)
        .flatMap((message) => {
          if (message.message && message.message.includes("[new_message]")) {
            return message.message
              .split("[new_message]")
              .map((text, index) => ({
                ...message,
                message: text,
                timestamp: message.timestamp + `_${index}`,
              }));
          }
          return [message];
        })
        .map((message, index) => {
          const isOperator = message.sender.startsWith("operator");
          const operatorEmail = isOperator
            ? message.sender.split("-").slice(1).join("-")
            : "";
          const Component =
            isOperator || message.sender === "ai"
              ? ChatBubbleOperator
              : ChatBubbleUser;

          let messageText = message.message;
          if (
            message.sender === "ai" &&
            messageText &&
            messageText.includes("[delete]")
          ) {
            const parts = messageText.split("[delete]");
            messageText = parts.length > 1 ? parts[1].trim() : parts[0].trim();
          }

          return (
            <Component key={index} mb={2}>
              {isOperator && (
                <Typography variant="caption" fontWeight="bold" mb={0.5}>
                  {operatorEmail}
                </Typography>
              )}
              {message.attachments &&
                message.attachments.map((attachment, idx) => (
                  <Box key={idx} mt={1}>
                    {attachment.type === "image" && (
                      <Box
                        mt={1}
                        display="flex"
                        justifyContent="center"
                        onClick={() => handleImageClick(attachment.url)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={attachment.url}
                          alt={attachment.file_name || t("attachment")}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "50vh",
                            borderRadius: "8px",
                          }}
                        />
                      </Box>
                    )}
                    {attachment.type === "audio" && (
                      <Box mt={1}>
                        <audio controls src={attachment.url}>
                          {t("audio_not_supported")}
                        </audio>
                      </Box>
                    )}
                    {!attachment.type.startsWith("image") &&
                      !attachment.type.startsWith("audio") && (
                        <Box mt={1}>
                          <Button
                            variant="outlined"
                            component="a"
                            href={attachment.url}
                            download={attachment.filename || t("file")}
                            style={{
                              fontSize: "12px",
                              padding: 0,
                              margin: 0,
                              border: 0,
                            }}
                          >
                            {t("download")} {attachment.filename || t("file")}
                          </Button>
                        </Box>
                      )}
                  </Box>
                ))}
              {messageText && (
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  breaks={true}
                  className={style.reactMarkDown}
                >
                  {decodeMessage(messageText)}
                </ReactMarkdown>
              )}
              <Typography variant="caption" color="textSecondary">
                {new Date(message.timestamp.split("_")[0]).toLocaleString()}
              </Typography>
              {message.context_info && (
                <Tooltip title={t("view_message_info")}>
                  <IconButton onClick={() => handleOpenContextDialog(message)}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Component>
          );
        })}

      {/* Context Dialog */}
      <Dialog
        open={openContextDialog}
        onClose={handleCloseContextDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>{t("context_information")}</DialogTitle>
        <DialogContent>
          {selectedMessage && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6">{t("original_message")}</Typography>
                <Paper
                  elevation={1}
                  style={{ padding: "10px", wordBreak: "break-word" }}
                >
                  <ReactMarkdown remarkPlugins={[remarkGfm]} breaks={true}>
                    {decodeMessage(selectedMessage.message)}
                  </ReactMarkdown>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">{t("context_info")}</Typography>
                {selectedMessage.context_info
                  .filter((contextItem) =>
                    Object.values(contextItem).some(
                      (value) => String(value).trim() !== ""
                    )
                  )
                  .map((contextItem, index) => (
                    <Accordion
                      expanded={expandedAccordion === index}
                      onChange={handleAccordionChange(index)}
                      key={index}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{`${t("Context Info")} ${
                          index + 1
                        }`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {Object.entries(contextItem)
                          .filter(([key, value]) => String(value).trim() !== "")
                          .map(([key, value], idx) => {
                            if (key === "docs" && Array.isArray(value)) {
                              // Handle the "docs" key specifically
                              return (
                                <Box key={idx} mb={2}>
                                  <Typography variant="body2" fontWeight="bold">
                                    {t("Documents")}:
                                  </Typography>
                                  {value.map((doc, docIdx) => (
                                    <Paper
                                      key={doc.document_id || docIdx}
                                      elevation={1}
                                      style={{
                                        padding: "10px",
                                        wordBreak: "break-word",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        fontWeight="bold"
                                      >
                                        {t("Document")} {docIdx + 1}
                                      </Typography>
                                      <Button
                                        variant="outlined"
                                        onClick={() =>
                                          navigate(
                                            `/documents/${encodeURIComponent(
                                              doc.device_id
                                            )}/${encodeURIComponent(
                                              doc.document_id
                                            )}`
                                          )
                                        }
                                      >
                                        {t("view_full_document")}
                                      </Button>
                                      <Typography variant="body2" gutterBottom>
                                        {doc.content.substring(0, 1000)}...
                                      </Typography>
                                    </Paper>
                                  ))}
                                </Box>
                              );
                            }

                            // Handle all other keys as before
                            return (
                              <Box key={idx} mb={2}>
                                <Typography variant="body2" fontWeight="bold">
                                  {t(key)}:
                                </Typography>
                                <Paper
                                  elevation={1}
                                  style={{
                                    padding: "10px",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <ReactMarkdown
                                    remarkPlugins={[remarkGfm]}
                                    breaks={true}
                                  >
                                    {decodeMessage(String(value))}
                                  </ReactMarkdown>
                                </Paper>
                              </Box>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <Button
          onClick={handleCloseContextDialog}
          variant="contained"
          style={{ margin: "20px" }}
        >
          {t("close")}
        </Button>
      </Dialog>

      {/* Fullscreen Image Modal */}
      {fullscreenImage && (
        <Dialog open={true} onClose={handleCloseFullscreen} maxWidth="xl">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            bgcolor="black"
          >
            <img
              src={fullscreenImage}
              alt={t("attachment")}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              onClick={handleCloseFullscreen} // Allow closing by clicking the image
            />
          </Box>
        </Dialog>
      )}
    </ChatHistory>
  );
};

export default ChatMessages;
