import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, CssBaseline, Snackbar, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import Joyride, { STATUS } from "react-joyride";

import Layout from "./containers/Layout/Layout";
import Dashboard from "./containers/Dashboard/Dashboard";
import Documents from "./containers/Documents/Documents";
import Settings from "./containers/Settings/Settings";
import Login from "./containers/Login/Login";
import PrivateRoute from "./containers/PrivateRoute/PrivateRoute";
import ChatsView from "./containers/Chats/ChatsView";
import Users from "./containers/Users/Users";
import theme from "./styles/theme";
import { TasksProvider } from "./context/TasksContext";
import { TaskDrawerProvider } from "./context/TaskDrawerContext";
import { SnackbarProvider } from "./context/SnackbarContext";
import { ModalProvider } from "./context/ModalContext";

global.ResizeObserver = class {
  observe() {}
  unobserve() {}
  disconnect() {}
};

const App = () => {
  const { t } = useTranslation("app");

  // State for managing the tutorial
  const [runTutorial, setRunTutorial] = useState(false);
  const [steps] = useState([
    // Navbar section
    {
      target: ".language-selector", // Language change selector
      content: t("tutorial_change_language"), // Explain how to change language
    },
    // Sidebar section
    {
      target: ".sidebar-dashboard", // Dashboard in sidebar
      content: t("tutorial_sidebar_dashboard"), // Explain the dashboard panel
    },
    {
      target: ".sidebar-chats", // Chats in sidebar
      content: t("tutorial_sidebar_chats"), // Explain the chats panel
    },
    {
      target: ".sidebar-documents", // Documents in sidebar
      content: t("tutorial_sidebar_documents"), // Explain the documents panel
    },
    {
      target: ".sidebar-users", // Users in sidebar
      content: t("tutorial_sidebar_users"), // Explain the users panel
    },
    // Profile Sidebar from the right
    {
      target: ".profile-button", // Button to open profile sidebar
      content: t("tutorial_profile_sidebar"), // Explain the profile actions (logout, etc.)
    },
    // Dashboard section
    {
      target: ".credits-used", // Target element by CSS selector
      content: t("tutorial_credits_used"), // Explain what credits used means
    },
    {
      target: ".credits-limit",
      content: t("tutorial_credits_limit"), // Explain the credits limit
    },
    // Chats section
    {
      target: ".chat-list",
      content: t("tutorial_chat_list"), // Explain the chat list section
    },
    {
      target: ".chat-messages",
      content: t("tutorial_chat_messages"), // Explain chat messages
    },
    // Documents section
    {
      target: ".device-list",
      content: t("tutorial_device_list"), // Explain device list
    },
    {
      target: ".document-list",
      content: t("tutorial_document_list"), // Explain document list
    },
    {
      target: ".document-actions",
      content: t("tutorial_document_actions"), // Explain actions like adding or deleting documents
    },
    // Users section
    {
      target: ".user-management",
      content: t("tutorial_user_management"), // Explain user management section
    },
  ]);

  // Handle Joyride callback (optional: stop or handle different states)
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRunTutorial(false); // Stop the tutorial when it finishes or is skipped
    }
  };

  // Delay starting the tutorial until after the components have rendered
  useEffect(() => {
    const activate = () => {
      if (document.querySelector(".credits-used")) {
        setRunTutorial(true); // Start the tutorial after components have mounted
      } else {
        const timer = setTimeout(activate, 1000);
        return () => clearTimeout(timer); // Clean up the timer on unmount
      }
    };
    activate();
  }, []);

  const [message, setMessage] = useState(false);
  const [messageMode, setMessageMode] = useState(false);

  const setDisplayMessage = (content = "", type = "error") => {
    setMessage(content);
    setMessageMode(type);
  };

  const handleCloseError = () => {
    setMessage("");
    setMessageMode("");
  };

  return (
    <ThemeProvider theme={theme}>
      <TasksProvider>
        <TaskDrawerProvider>
          <SnackbarProvider>
            <ModalProvider>
              <CssBaseline />
              <Joyride
                steps={steps}
                run={runTutorial} // Runs tutorial on load
                continuous={true}
                scrollToFirstStep={true}
                showProgress={true}
                showSkipButton={true}
                callback={handleJoyrideCallback}
                styles={{
                  options: {
                    zIndex: 10000, // Ensure the highest z-index
                  },
                }}
              />

              <Router>
                <Routes>
                  <Route
                    path="/dashboard"
                    element={
                      <PrivateRoute section="backoffice_dashboard">
                        <Layout sectionTitle={t("dashboard")}>
                          <Dashboard setDisplayMessage={setDisplayMessage} />
                        </Layout>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/chats/:userId?/:chatId?"
                    element={
                      <PrivateRoute section="backoffice_chat">
                        <Layout sectionTitle={t("chats")}>
                          <ChatsView setDisplayMessage={setDisplayMessage} />
                        </Layout>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/documents/:deviceId?/:documentId?"
                    element={
                      <PrivateRoute section="backoffice_documents">
                        <Layout sectionTitle={t("documents")}>
                          <Documents setDisplayMessage={setDisplayMessage} />
                        </Layout>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/users"
                    element={
                      <PrivateRoute section="backoffice_users">
                        <Layout sectionTitle={t("users")}>
                          <Users setDisplayMessage={setDisplayMessage} />
                        </Layout>
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <PrivateRoute section="backoffice_config">
                        <Layout sectionTitle={t("config")}>
                          <Settings setDisplayMessage={setDisplayMessage} />
                        </Layout>
                      </PrivateRoute>
                    }
                  />
                  <Route path="/" element={<Login />} />
                </Routes>
              </Router>

              {/* Snackbar for Errors */}
              {message && (
                <Snackbar
                  open={!!(message && message.length)} // Cast to boolean
                  autoHideDuration={6000}
                  onClose={handleCloseError}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    onClose={handleCloseError}
                    severity={messageMode || "error"}
                  >
                    {message}
                  </Alert>
                </Snackbar>
              )}
            </ModalProvider>
          </SnackbarProvider>
        </TaskDrawerProvider>
      </TasksProvider>
    </ThemeProvider>
  );
};

export default App;
