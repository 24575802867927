export const PARAM_TYPES = {
  STRING: "string",
  BOOLEAN: "boolean",
  ARRAY_OF_STRINGS: "string[]",
  NUMBER: "number",
  OPTIONS: "options",
  DICT: "dict",
};

export const generalParams = [
  {
    name: "task_name",
    type: PARAM_TYPES.OPTIONS,
    default: "ReturnMessage",
    options: [
      "ReturnMessage",
      "LLMClassification",
      "LLMGen",
      "RAG",
      "Checker",
      "Curl",
      "ImageDetection",
      "GPTImageClassification",
      "Retrieve",
    ],
  },
  {
    name: "name",
    type: PARAM_TYPES.STRING,
    default: null,
    mandatory: true,
  },
  {
    name: "description",
    type: PARAM_TYPES.STRING,
    default: null,
  },
  {
    name: "start",
    type: PARAM_TYPES.BOOLEAN,
    default: false,
    unique: true,
  },
  {
    name: "end",
    type: PARAM_TYPES.BOOLEAN,
    default: false,
  },
  {
    name: "wait",
    type: PARAM_TYPES.BOOLEAN,
    default: false,
  },
  {
    name: "fail",
    type: PARAM_TYPES.BOOLEAN,
    default: false,
  },
  {
    name: "new_message",
    type: PARAM_TYPES.BOOLEAN,
    default: false,
  },
  {
    name: "metadata_key",
    type: PARAM_TYPES.STRING,
    default: [],
  },
  {
    name: "metadata_type",
    type: PARAM_TYPES.OPTIONS,
    default: [],
    options: ["json", "text"],
  },
  {
    name: "message_type",
    type: PARAM_TYPES.OPTIONS,
    default: ["text"],
    options: ["text", "image", "system"],
    isMultiple: true,
  },
  {
    name: "humanize",
    type: PARAM_TYPES.BOOLEAN,
    default: true,
  },
  {
    name: "clean",
    type: PARAM_TYPES.BOOLEAN,
    default: false,
  },
  {
    name: "orchestrator",
    type: PARAM_TYPES.BOOLEAN,
    default: false,
    unique: true,
  },
  {
    name: "chat_state",
    type: PARAM_TYPES.OPTIONS,
    default: [],
    options: ["resolved", "active", "human_required"],
  },
  {
    name: "optional",
    type: PARAM_TYPES.BOOLEAN,
    default: false,
  },
];

export const ReturnMessageParams = [
  {
    name: "message",
    type: PARAM_TYPES.STRING,
    default: "",
  },
];

export const LLMClassificationParams = [
  {
    name: "prompt_message",
    type: PARAM_TYPES.STRING,
    mandatory: true,
  },
  {
    name: "categories",
    type: PARAM_TYPES.ARRAY_OF_STRINGS,
    default: [],
  },
  {
    name: "chat_history_include_ai",
    type: PARAM_TYPES.BOOLEAN,
    default: false,
  },
  {
    name: "n_chat_history",
    type: PARAM_TYPES.NUMBER,
    default: -1,
  },
];

export const LLMGenParams = [
  {
    name: "prompt_message",
    type: PARAM_TYPES.STRING,
    mandatory: true,
  },
  {
    name: "chat_history_include_ai",
    type: PARAM_TYPES.BOOLEAN,
    default: false,
  },
  {
    name: "n_chat_history",
    type: PARAM_TYPES.NUMBER,
    default: -1,
  },
];

export const RAGParams = [
  {
    name: "qa_system_prompt",
    type: PARAM_TYPES.STRING,
    default: "Provide a detailed response based on the following context.",
  },
  {
    name: "vectorstore_id",
    type: PARAM_TYPES.STRING,
    default: null,
  },
  {
    name: "n_documents",
    type: PARAM_TYPES.NUMBER,
    default: 5,
  },
  {
    name: "n_documents_threshold",
    type: PARAM_TYPES.NUMBER,
    default: 1,
  },
  {
    name: "default_answer",
    type: PARAM_TYPES.STRING,
    default: "No se encontró información relevante.",
  },
  {
    name: "save_intermediate",
    type: PARAM_TYPES.STRING,
    default: null,
  },
];

export const CheckerParams = [
  {
    name: "metadata_key",
    type: PARAM_TYPES.STRING,
    mandatory: true,
  },
  {
    name: "comparison",
    type: PARAM_TYPES.OPTIONS,
    default: "exists",
    options: [
      "exists",
      "not_exists",
      "in",
      "not_in",
      "equal",
      "not_equal",
      "startswith",
    ],
  },
  {
    name: "value",
    type: PARAM_TYPES.ARRAY_OF_STRINGS,
    default: [],
  },
];

export const CurlParams = [
  {
    name: "url",
    type: PARAM_TYPES.STRING,
    default: null,
    mandatory: true,
  },
  {
    name: "data",
    type: PARAM_TYPES.DICT,
    default: null,
  },
  {
    name: "message",
    type: PARAM_TYPES.STRING,
    default: "Default message",
  },
  {
    name: "post_process",
    type: PARAM_TYPES.STRING,
    default: null,
  },
];

export const ImageDetectionParams = [
  {
    name: "models",
    type: PARAM_TYPES.STRING,
    mandatory: true,
  },
  {
    name: "metadata_type",
    type: PARAM_TYPES.OPTIONS,
    default: [],
    options: ["json", "text"],
  }
];

export const GPTImageClassificationParams = [
  {
    name: "prompt_message",
    type: PARAM_TYPES.STRING,
    default: "Please classify this input",
  },
  {
    name: "categories",
    type: PARAM_TYPES.ARRAY_OF_STRINGS,
    default: [],
  },
  {
    name: "image_size",
    type: PARAM_TYPES.NUMBER,
    default: 128,
  }
];

export const RetrieveParams = [
  {
    name: "path",
    type: PARAM_TYPES.STRING,
    default: null,
    mandatory: true,
  },
  {
    name: "search_value",
    type: PARAM_TYPES.STRING,
  },
  {
    name: "search_value_prefix",
    type: PARAM_TYPES.STRING,
    default: null,
  }

];

export const specificParams = {
  LLMClassification: LLMClassificationParams,
  ReturnMessage: ReturnMessageParams,
  LLMGen: LLMGenParams,
  ImageDetection: ImageDetectionParams,
  RAG: RAGParams,
  GPTImageClassification: GPTImageClassificationParams,
  Checker: CheckerParams,
  Retrieve: RetrieveParams,
  Curl: CurlParams,
  Orchestrator: [],
};

export const comparisons = [
  {
    label: "Exists",
    value: "exists",
  },
  {
    label: "Not Exists",
    value: "not_exists",
  },
  {
    label: "Equal",
    value: "equal",
  },
  {
    label: "Not Equal",
    value: "not_equal",
  },
  {
    label: "In",
    value: "in",
  },
  {
    label: "Not In",
    value: "not_in",
  },
  {
    label: "Starts with",
    value: "startswith",
  },
];
