import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Toolbar,
  Divider,
  MenuItem,
  ListItemIcon,
  Button,
  Select,
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  AccountCircle,
  Notifications as NotificationsIcon,
  Close,
  ExitToApp,
  Edit as EditIcon,
  Delete as RemoveIcon,
  Visibility as ViewIcon,
  Add as AddIcon,
  Delete as TrashIcon,
  Newspaper as NewsIcon,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from "react-country-flag";
import { format } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { getData, patchData, deleteData } from "../../services/Api";
import {
  StyledAppBar,
  UserSection,
  CompanyName,
  UserButton,
  MenuPaper,
  MenuHeader,
  UserName,
  CloseButton,
  MenuItemText,
  LogoutMenuItem,
  LanguageSelectContainer,
} from "./NavbarStyles";
import { menuItems } from "../../utils/Config";
import notificationSound from "../../assets/sounds/notification.wav";
import { getCountryFlagAndUserId } from "../../utils/Chat";

const Navbar = ({ isMobile, toggleSidebar }) => {
  const [userInfo, setUserInfo] = useState({
    user_id: "",
    company: "",
    privileges: {},
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [anchorElNotif, setAnchorElNotif] = useState(null);
  const { t, i18n } = useTranslation("navbar");
  const navigate = useNavigate();
  const audioRef = useRef(new Audio(notificationSound));
  const intervalRef = useRef(null);
  const [changelog, setChangelog] = useState([]);
  const [anchorElNews, setAnchorElNews] = useState(null); // State for News menu
  const [isChangelogUpdated, setIsChangelogUpdated] = useState(false);

  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    const fetchClientData = async () => {
      const cacheKey = "clientData";
      const cacheTimestampKey = "clientDataTimestamp";
      const cacheDuration = 12 * 60 * 60 * 1000; // 12 hours

      // Check if data is cached and still valid
      const cachedData = localStorage.getItem(cacheKey);
      const cachedTimestamp = localStorage.getItem(cacheTimestampKey);
      const isCacheValid =
        cachedData &&
        cachedTimestamp &&
        new Date().getTime() - Number(cachedTimestamp) < cacheDuration;

      if (isCacheValid) {
        setClientData(JSON.parse(cachedData));
        return;
      }

      try {
        const data = await getData("/backoffice/clients/");
        if (data) {
          setClientData(data);
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchClientData();
  }, [t]);

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission !== "granted") {
        Notification.requestPermission().then((permission) => {
          if (permission !== "granted") {
            console.warn("Desktop notifications permission not granted");
          }
        });
      }
    } else {
      console.warn("Notifications are not supported in this browser.");
    }
    fetchChangelog();
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserInfo({
        user_id: parsedUser.user_id,
        company: parsedUser.company,
        privileges: parsedUser.privilege,
      });
    }
    fetchNotifications();
  }, []);

  const getNotificationMessage = (notification, t) => {
    let chat_id = notification.metadata?.chat_id;
    if (
      notification.metadata?.chat_id &&
      notification.metadata.chat_id.startsWith("whatsapp:")
    ) {
      chat_id = getCountryFlagAndUserId(
        notification.metadata.chat_id.split("-")[
          notification.metadata.chat_id.split("-").length - 1
        ]
      );
    }
    return notification.type === "chat_requires_assistance"
      ? t("chat_requires_assistance", { chat_id })
      : notification.message || t("new_notification");
  };

  const fetchNotifications = async () => {
    clearTimeout(intervalRef.current);
    let newNotifications = 0;

    try {
      const response = await getData("/backoffice/notifications");
      newNotifications = response.length;

      let prevTimestamp = localStorage.getItem("latestTimestamp")
        ? Number(localStorage.getItem("latestTimestamp"))
        : 0;

      const latestTimestamp = response.reduce(
        (max, notification) =>
          Math.max(max, new Date(notification.timestamp).getTime()),
        prevTimestamp
      );
      if (latestTimestamp > prevTimestamp) {
        localStorage.setItem("latestTimestamp", latestTimestamp);
        audioRef.current.play();

        const unseenNotifications = response.filter(
          (n) => !n.seen && new Date(n.timestamp).getTime() > prevTimestamp
        );

        if ("Notification" in window && Notification.permission === "granted") {
          if (unseenNotifications.length <= 3) {
            unseenNotifications.forEach((notification) => {
              const notificationMessage = getNotificationMessage(
                notification,
                t
              );

              const desktopNotification = new Notification(
                t("new_notification"),
                {
                  body: notificationMessage,
                  icon: "https://panel.manuar.ai/manuar-icon.png",
                }
              );

              desktopNotification.onclick = async () => {
                await patchData(
                  `/backoffice/notifications/${notification.id}`,
                  { delete: true }
                );
                if (
                  notification.type === "chat_requires_assistance" &&
                  notification.metadata?.chat_id
                ) {
                  window.open(
                    `/chats/${notification.metadata.user_id}/${notification.metadata.chat_id}`,
                    "_blank"
                  );
                } else {
                  window.open("/chats/", "_blank");
                }
                desktopNotification.close();
              };
            });
          } else {
            const desktopNotification = new Notification(
              t("new_notification"),
              {
                body: `${unseenNotifications.length} ${t("new_notification")}`,
                icon: "https://panel.manuar.ai/manuar-icon.png",
              }
            );
            desktopNotification.onclick = () => {
              window.open("/chats/", "_blank");
              desktopNotification.close();
            };
          }
        }
      }
      setNotifications([...response]);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }

    intervalRef.current = setTimeout(fetchNotifications, 1500);
  };

  // Remaining code for Navbar component...
  const handleNotificationMenuOpen = (event) => {
    setAnchorElNotif(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setAnchorElNotif(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  };

  const deleteNotification = async (notification) => {
    const { id, metadata, type } = notification;
    try {
      await patchData(`/backoffice/notifications/${id}`, {
        delete: true,
      });

      if (type === "chat_requires_assistance" && metadata?.chat_id) {
        navigate(`/chats/${metadata.user_id}/${metadata.chat_id}`);
      }

      setNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n.id !== id)
      );
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const clearAllNotifications = async () => {
    try {
      await deleteData("/backoffice/notifications");
      setNotifications([]);
    } catch (error) {
      console.error("Error clearing notifications:", error);
    }
  };

  const unseenCount = notifications.filter((n) => !n.seen).length;

  const fetchChangelog = async (forceRefresh = false) => {
    if (forceRefresh) {
      // Clear cached data and timestamp if forceRefresh is true
      localStorage.removeItem("changelog");
      localStorage.removeItem("changelogTimestamp");
    }

    const cachedChangelog = localStorage.getItem("changelog");
    const cachedTimestamp = localStorage.getItem("changelogTimestamp");

    if (cachedChangelog && cachedTimestamp && !forceRefresh) {
      const isCacheValid =
        new Date().getTime() - Number(cachedTimestamp) < 12 * 60 * 60 * 1000; // Cache valid for 12 hours
      if (isCacheValid) {
        setChangelog(JSON.parse(cachedChangelog));
        return;
      }
    }

    try {
      const response = await getData("/backoffice/changelog"); // Backend endpoint for changelog

      // Compare with cached changelog
      const cachedData = cachedChangelog ? JSON.parse(cachedChangelog) : [];
      const isUpdated = JSON.stringify(cachedData) !== JSON.stringify(response);

      setChangelog(response);
      setIsChangelogUpdated(isUpdated); // Set update notification flag
      localStorage.setItem("changelog", JSON.stringify(response));
      localStorage.setItem(
        "changelogTimestamp",
        new Date().getTime().toString()
      );
    } catch (error) {
      console.error("Error fetching changelog:", error);
    }
  };

  const getLanguageText = (log) => {
    switch (i18n.language) {
      case "es":
        return log.text_es;
      case "pt":
        return log.text_pt;
      default:
        return log.text_en;
    }
  };

  const getLocale = () => {
    switch (i18n.language) {
      case "es":
        return es;
      case "pt":
        return ptBR;
      default:
        return enUS;
    }
  };

  const handleNewsMenuOpen = (event) => {
    setAnchorElNews(event.currentTarget);
    setIsChangelogUpdated(false); // Clear the badge
  };

  const handleNewsMenuClose = () => {
    setAnchorElNews(null);
  };

  const getRepoName = (repo) => {
    switch (repo) {
      case "chat":
        return t("chat");
      case "backend":
        return t("backend");
      case "backoffice":
        return t("backoffice");
      default:
        return repo; // Fallback for unrecognized repo names
    }
  };

  const groupChangelogByDayAndRepo = (changelog) => {
    return changelog.reduce((grouped, log) => {
      const date = new Date(log.timestamp);
      if (isNaN(date)) {
        console.warn("Invalid timestamp:", log.timestamp);
        return grouped; // Skip invalid entries
      }

      // Format date as YYYY-MM-DD
      const formattedDate = date.toLocaleDateString("en-US", {
        timeZone: "UTC",
      });

      // Initialize the date group if not present
      if (!grouped[formattedDate]) {
        grouped[formattedDate] = {};
      }

      // Initialize the repo group for the date if not present
      if (!grouped[formattedDate][log.repo]) {
        grouped[formattedDate][log.repo] = [];
      }

      // Push the log into the corresponding repo group
      grouped[formattedDate][log.repo].push(log);

      return grouped;
    }, {});
  };

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        {isMobile && (
          <IconButton color="black" onClick={toggleSidebar}>
            <MenuIcon />
          </IconButton>
        )}
        <UserSection>
          {/* Notification Icon */}
          <IconButton color="black" onClick={handleNotificationMenuOpen}>
            <Badge badgeContent={unseenCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <MenuPaper
            anchorEl={anchorElNotif}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            open={Boolean(anchorElNotif)}
            onClose={handleNotificationMenuClose}
          >
            {notifications.length > 0 && (
              <center>
                <Button onClick={clearAllNotifications} color="error">
                  <TrashIcon />
                  {t("clear_all")}
                </Button>
              </center>
            )}

            <List>
              {notifications.length > 0 ? (
                notifications.map((notification) => {
                  let chat_id = notification.metadata?.chat_id;
                  if (notification.metadata?.chat_id.startsWith("whatsapp:")) {
                    chat_id = getCountryFlagAndUserId(
                      notification.metadata?.chat_id.split("-")[
                        notification.metadata?.chat_id.split("-").length - 1
                      ]
                    );
                  }
                  return (
                    <ListItem
                      key={notification.id}
                      button
                      onClick={() => deleteNotification(notification)}
                      sx={{
                        backgroundColor: notification.seen
                          ? "#f0f0f0"
                          : "#ffffff",
                      }} // Different background for seen/unseen notifications
                    >
                      <ListItemText
                        primary={
                          notification.type === "chat_requires_assistance"
                            ? t("chat_requires_assistance", {
                                chat_id,
                              })
                            : notification.message
                        }
                        secondary={format(
                          new Date(notification.timestamp),
                          "PPpp" // Formats the timestamp into a readable format
                        )}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <ListItem>
                  <ListItemText primary={t("no_notifications")} />
                </ListItem>
              )}
            </List>
          </MenuPaper>

          <IconButton color="black" onClick={handleNewsMenuOpen}>
            <Badge
              color="error"
              variant="dot"
              invisible={!isChangelogUpdated} // Show badge only if changelog is updated
            >
              <NewsIcon />
            </Badge>
          </IconButton>

          <MenuPaper
            anchorEl={anchorElNews}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            open={Boolean(anchorElNews)}
            onClose={handleNewsMenuClose}
          >
            {changelog.length > 0 && (
              <center>
                <Button
                  onClick={() => fetchChangelog(true)} // Force refresh
                  color="info"
                >
                  {t("refresh_changelog")}
                </Button>
              </center>
            )}
            <List>
              {changelog.length > 0 ? (
                Object.entries(groupChangelogByDayAndRepo(changelog)).map(
                  ([date, repos], dateIndex) => (
                    <div key={dateIndex}>
                      {/* Date Header */}
                      <ListItem
                        sx={{
                          justifyContent: "center", // Center the date
                          backgroundColor: "#f9f9f9", // Light background for separation
                          fontWeight: "bold", // Bold text
                          fontSize: "1.25rem", // Larger font size
                          py: 1.5, // Add vertical padding
                          borderBottom: "1px solid #ddd", // Optional border for clarity
                        }}
                      >
                        <ListItemText
                          primary={
                            isNaN(new Date(date))
                              ? t("invalid_date")
                              : format(new Date(date), "PPP", {
                                  locale: getLocale(),
                                })
                          }
                          primaryTypographyProps={{
                            align: "center", // Center align text
                            fontWeight: "bold", // Bold text
                          }}
                        />
                      </ListItem>

                      {/* Repo Groups */}
                      {Object.entries(repos).map(([repo, logs], repoIndex) => (
                        <div key={repoIndex}>
                          {/* Repo Header */}
                          <ListItem>
                            <ListItemText
                              primary={
                                <strong>{`${getRepoName(repo)}:`}</strong>
                              }
                            />
                          </ListItem>

                          {/* Logs under Repo */}
                          {logs.map((log, logIndex) => (
                            <ListItem key={logIndex}>
                              <ListItemText
                                primary={getLanguageText(log)}
                                secondary={
                                  isNaN(new Date(log.timestamp))
                                    ? t("invalid_date")
                                    : format(new Date(log.timestamp), "p", {
                                        locale: getLocale(),
                                      })
                                }
                              />
                            </ListItem>
                          ))}
                        </div>
                      ))}
                    </div>
                  )
                )
              ) : (
                <ListItem>
                  <ListItemText primary={t("no_changelog")} />
                </ListItem>
              )}
            </List>
          </MenuPaper>

          {!isMobile && (
            <LanguageSelectContainer>
              <Select
                value={i18n.language}
                onChange={handleLanguageChange}
                variant="outlined"
                size="small"
                fullWidth
                className="language-selector"
                sx={{ marginRight: 2 }}
              >
                <MenuItem value="en">
                  <ReactCountryFlag
                    countryCode="US"
                    svg
                    style={{ marginRight: 8 }}
                  />
                  {t("language_english")}
                </MenuItem>
                <MenuItem value="es">
                  <ReactCountryFlag
                    countryCode="ES"
                    svg
                    style={{ marginRight: 8 }}
                  />
                  {t("language_spanish")}
                </MenuItem>
                <MenuItem value="pt">
                  <ReactCountryFlag
                    countryCode="BR"
                    svg
                    style={{ marginRight: 8 }}
                  />
                  {t("language_portuguese")}
                </MenuItem>
              </Select>
            </LanguageSelectContainer>
          )}

          <UserButton
            aria-controls="privileges-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            className="profile-button"
          >
            {clientData && clientData.logo ? (
              <img
                src={clientData.logo}
                alt={`${userInfo.company} Logo`}
                style={{
                  width: isMobile ? "15vw" : "50px",
                  maxHeight: "35px",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <AccountCircle />
            )}
          </UserButton>
          <MenuPaper
            id="privileges-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuHeader>
              <UserName variant="subtitle1">
                <CompanyName
                  variant="body1"
                  style={{ fontSize: "2vh", whiteSpace: "nowrap" }}
                >
                  {userInfo.company}
                </CompanyName>
                {userInfo.user_id}
              </UserName>
              <CloseButton size="small" onClick={handleMenuClose}>
                <Close />
              </CloseButton>
            </MenuHeader>
            <Divider />
            {menuItems.map(({ section, text, icon }) => {
              const privileges = userInfo.privileges[section] || [];
              if (privileges.length > 0) {
                return (
                  <MenuItem key={section}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <MenuItemText>{t(text.toLowerCase())}</MenuItemText>
                    {privileges.map((privilege, index) => (
                      <ListItemIcon key={index}>
                        {privilege === "view" && <ViewIcon />}
                        {privilege === "create" && <AddIcon />}
                        {privilege === "edit" && <EditIcon />}
                        {privilege === "remove" && <RemoveIcon />}
                      </ListItemIcon>
                    ))}
                  </MenuItem>
                );
              }
              return null;
            })}
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <ExitToApp fontSize="small" />
              </ListItemIcon>
              <LogoutMenuItem>{t("logout")}</LogoutMenuItem>
            </MenuItem>
          </MenuPaper>
        </UserSection>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;
