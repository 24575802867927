import React, { useCallback, useEffect, useMemo, memo } from "react";
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  MarkerType,
  Position,
  useReactFlow,
} from "@xyflow/react";
import Node from "./Node";
import FloatingEdge from "./FloatingEdge";
import CustomConnectionLine from "./CustomConnectionLine";
import { useTasks } from "../../context/TasksContext";
import "@xyflow/react/dist/style.css";
import { v4 as uuidv4 } from "uuid";
import SelfConnectingEdge from "./SelfConnectingEdge";
import BiDirectionalEdge from "./BiDirectionalEdge";
import useClientData from "../../hooks/useClientData";
import { useTaskDrawer } from "../../context/TaskDrawerContext";
import { useModal } from "../../context/ModalContext";
import { useSnackbar } from "../../context/SnackbarContext";

const connectionLineStyle = {
  stroke: "#b1b1b7",
};

const NodeEditor = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const { openDrawer } = useTaskDrawer();

  const {
    tasks,
    setTasks,
    getNodes,
    getEdges,
    deleteTask,
    getTask,
    setNextTask,
  } = useTasks();
  const { postTasks } = useClientData();

  const { getViewport } = useReactFlow();
  const { openModal } = useModal();
  const { openSnackbar } = useSnackbar();

  const updateNodesAndEdges = useCallback(() => {
    const newNodes = getNodes();
    const newEdges = getEdges();

    setNodes((currentNodes) => {
      const hasSignificantChange =
        currentNodes.length !== newNodes.length ||
        newNodes.some((newNode, index) => {
          const currentNode = currentNodes[index];
          return (
            !currentNode ||
            currentNode.id !== newNode.id ||
            currentNode.data.label !== newNode.data.label ||
            currentNode.data.taskType !== newNode.data.taskType ||
            currentNode.data.description !== newNode.data.description ||
            currentNode.data.start !== newNode.data.start ||
            currentNode.data.orchestrator !== newNode.data.orchestrator ||
            currentNode.data.end !== newNode.data.end ||
            currentNode.data.metadataKey !== newNode.data.metadataKey ||
            currentNode.data.nextTask !== newNode.data.nextTask
          );
        });

      return hasSignificantChange ? newNodes : currentNodes;
    });

    setEdges(newEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNodes, getEdges]);

  useEffect(() => {
    updateNodesAndEdges();
  }, [tasks, updateNodesAndEdges]);

  const handleNodesChange = (changes) => {
    changes.forEach((change) => {
      change.type !== "remove" && onNodesChange(changes);
      if (change.type === "position" && !change.dragging) {
        const updatedTasks = tasks.map((task) =>
          task.id === change.id ? { ...task, position: change.position } : task
        );

        setTasks(updatedTasks);

        postTasks(updatedTasks).catch((error) => {
          console.error("Error updating task position", error);
        });
      } else if (change.type === "remove") {
        handleOpenDeleteModal(change.id, changes);
      }
    });
  };

  const handleOpenDeleteModal = (taskId, changes) => {
    const task = getTask(taskId);
    openModal({
      title: "Eliminar tarea",
      description: `Estás a punto de eliminar la tarea ${task.name}.`,
      explanation:
        "Esta acción también eliminará todas sus conexiones asociadas.",
      onConfirmation: () => {
        handleDeleteTask(taskId);
        onNodesChange(changes);
      },
    });
  };

  const handleDeleteTask = async (taskId) => {
    await deleteTask(taskId);
    openSnackbar("Tarea eliminada correctamente!");
  };

  const handleOpenNewEdgeModal = (params) => {
    const taskId = params.source;
    const targetId = params.target;
    openModal({
      title: "Nueva conexión",
      description: `Estás a punto de crear una nueva conexión. ¿De qué tipo deseas que sea?`,
      radioButtons: [
        { label: "Next Task", value: "next_task" },
        { label: "Category Next Task", value: "category_next_task" },
      ],
      explanation:
        "En caso de seleccionar Next Task, esta acción se realizará de inmediato",
      onConfirmation: (selectedValue) => {
        switch (selectedValue) {
          case "category_next_task":
            openDrawer(getTask(taskId), false, 2, targetId);
            break;
          default:
            setNextTask(taskId, targetId);
        }
      },
    });
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onLabelChange = useCallback(
    (id, newLabel) => {
      setNodes((nds) =>
        nds.map((node) =>
          node.id === id
            ? { ...node, data: { ...node.data, label: newLabel || "nodo" } }
            : node
        )
      );
    },
    [setNodes]
  );

  const onEdgeDoubleClick = useCallback(
    (_, edge) => {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    },
    [setEdges]
  );

  const nodeTypes = useMemo(
    () => ({
      custom: memo((node) => (
        <Node
          id={node.id}
          data={node.data}
          onLabelChange={onLabelChange}
          sourcePosition={Position.Bottom}
          targetPosition={Position.Top}
        />
      )),
    }),
    [onLabelChange]
  );

  const edgeTypes = useMemo(
    () => ({
      self: SelfConnectingEdge,
      bezier: SelfConnectingEdge,
      floating: FloatingEdge,
      bidirectional: BiDirectionalEdge,
    }),
    []
  );

  const defaultEdgeOptions = useMemo(
    () => ({
      type: "floating",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: "#b1b1b7",
      },
    }),
    []
  );

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const task = JSON.parse(event.dataTransfer.getData("task"));
      const viewportPosition = { x: event.clientX, y: event.clientY };
      const { x, y, zoom } = getViewport();

      const position = {
        x: (viewportPosition.x - x) / zoom,
        y: (viewportPosition.y - y) / zoom,
      };

      openDrawer({ id: uuidv4(), position, task_name: task.name }, true);
    },
    [getViewport, openDrawer]
  );

  return (
    <div
      style={{ width: "100vw", height: "100vh" }}
      onDrop={onDrop}
      onDragOver={onDragOver}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodesChange}
        onConnect={handleOpenNewEdgeModal}
        onEdgeDoubleClick={onEdgeDoubleClick}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView
        style={{ backgroundColor: "#F7F9FB" }}
        defaultEdgeOptions={defaultEdgeOptions}
        connectionLineComponent={CustomConnectionLine}
        connectionLineStyle={connectionLineStyle}
      >
        <Controls />
        <MiniMap />
        <Background variant="dots" gap={12} size={1} />
      </ReactFlow>
    </div>
  );
};

export default NodeEditor;
