import React from "react";
import {
  Box,
  Tabs,
  Tab,
  Button,
  Drawer,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import GeneralTab from "./Tabs/GeneralTab";
import ParametersTab from "./Tabs/ParametersTab";
import ConnectionsTab from "./Tabs/ConnectionsTab";
import { useTaskDrawer } from "../../context/TaskDrawerContext";
import { useTasks } from "../../context/TasksContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "../../context/SnackbarContext";
import { useModal } from "../../context/ModalContext";

const TaskDrawer = () => {
  const {
    open,
    closeDrawer,
    tabIndex,
    setTabIndex,
    task,
    isCreating,
    needSave,
    validateTask,
  } = useTaskDrawer();
  const { isOrchestrator, createTask, updateTask, deleteTask } = useTasks();

  const { openSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTabChange = (_, newValue) => {
    setTabIndex(newValue);
  };

  const saveTaskChanges = () => {
    isCreating ? createTask(task) : updateTask(task);
    closeDrawer();
    openSnackbar(`Tarea ${isCreating ? "creada" : "editada"} correctamente!`);
  };

  const disableTabs = isOrchestrator(task.id);

  const handleOpenDeleteModal = () => {
    openModal({
      title: "Eliminar tarea",
      description: `Estás a punto de eliminar la tarea ${
        task.name || task.id
      }.`,
      explanation:
        "Esta acción también eliminará todas las conexiones asociadas.",
      onConfirmation: handleDeleteTask,
    });
  };

  const handleDeleteTask = async () => {
    await deleteTask(task.id);
    closeDrawer();
    openSnackbar("Tarea eliminada correctamente!");
  };

  const handleCloseDrawer = () => {
    needSave
      ? openModal({
          title: "¿Descartar los cambios?",
          description: "Tienes cambios sin guardar en esta tarea.",
          explanation: "Si confirmas, los cambios realizados se perderán.",
          onConfirmation: closeDrawer,
        })
      : closeDrawer();
  };

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={open}
      onClose={handleCloseDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          width: isMobile ? "100%" : "45%",
          padding: 4,
          boxSizing: "border-box",
          height: isMobile ? "500px" : "100vh",
          borderRadius: isMobile ? "16px 16px 0 0" : "16px 0 0 16px",
        },
      }}
    >
      <Stack direction="row" spacing={2} justifyContent="end" mb={2}>
        <Button
          startIcon={<DeleteIcon />}
          color="error"
          onClick={handleOpenDeleteModal}
        >
          Eliminar
        </Button>
        <Button onClick={handleCloseDrawer} variant="outlined">
          Cerrar
        </Button>
        <Button
          variant="contained"
          onClick={saveTaskChanges}
          disabled={!validateTask() || !needSave}
        >
          {isCreating ? "Agregar" : "Editar"}
        </Button>
      </Stack>

      {/* Tabs para navegación */}
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
      >
        <Tab label="General" />
        <Tab label="Parámetros" disabled={disableTabs} />
        <Tab label="Conexiones" disabled={disableTabs} />
      </Tabs>

      {/* Renderizado dinámico del contenido de cada Tab */}
      <Box
        mt={2}
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: "column",
          height: "100%",
          overflowY: "scroll",
          padding: 1,
        }}
      >
        {tabIndex === 0 && <GeneralTab />}
        {tabIndex === 1 && <ParametersTab />}
        {tabIndex === 2 && <ConnectionsTab />}
      </Box>
    </Drawer>
  );
};

export default TaskDrawer;
