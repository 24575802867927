import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { hasPrivilege } from "../utils/AuthUtils";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  position: "relative",
}));

const StyledTableCell = styled(TableCell)(({ theme, isMobile }) => ({
  padding: isMobile ? "4px" : "16px",
  textAlign: "center",
  verticalAlign: "middle",
}));

const StyledIconButton = styled(IconButton)(({ theme, color }) => ({
  color: theme.palette.getContrastText(theme.palette.primary.light),
  backgroundColor:
    color === "delete"
      ? theme.palette.error.main + "20" // Adds 20% opacity
      : theme.palette.primary.light + "20", // Adds 20% opacity
  "&:hover": {
    backgroundColor:
      color === "delete"
        ? theme.palette.error.main + "40" // Adds 40% opacity on hover
        : theme.palette.primary.light + "40", // Adds 40% opacity on hover
  },
  margin: theme.spacing(0.25),
  padding: theme.spacing(0.75),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ManuarTable = ({ header = [], content = [], actions = {}, children }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Check if the device is mobile

  return (
    <StyledTableContainer component={Paper}>
      <Table>
        {/* Render Table Header */}
        <TableHead>
          <TableRow>
            {header.map((item, index) => (
              <StyledTableCell key={index} align="center" isMobile={isMobile}>
                {item}
              </StyledTableCell>
            ))}
            {/* Render Add Button if Defined */}
            {actions.add && (
              <StyledTableCell align="center" isMobile={isMobile}>
                {hasPrivilege("backoffice_users", "create") && (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={actions.add.icon}
                    onClick={actions.add.event}
                  >
                    {actions.add.text}
                  </Button>
                )}
              </StyledTableCell>
            )}
          </TableRow>
        </TableHead>

        {/* Render Table Body */}
        <TableBody>
          {content.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {/* Render Row Cells */}
              {row.map((cell, cellIndex) => (
                <StyledTableCell
                  key={cellIndex}
                  align="center"
                  isMobile={isMobile}
                >
                  {cell}
                </StyledTableCell>
              ))}

              {/* Render Actions */}
              <StyledTableCell
                align="center"
                isMobile={isMobile}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {hasPrivilege("backoffice_users", "edit") &&
                  Object.keys(actions)
                    .filter((key) => key !== "add")
                    .map((key, actionIndex) => (
                      <StyledIconButton
                        key={actionIndex}
                        color={actions[key].color}
                        onClick={() => actions[key].event(row)}
                        alt={actions[key].text}
                      >
                        {actions[key].icon}
                      </StyledIconButton>
                    ))}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Render Children */}
      {children}
    </StyledTableContainer>
  );
};

export default ManuarTable;
