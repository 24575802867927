import { Box, Typography } from "@mui/material";
import React from "react";
import { specificParams } from "../../../context/taskParamsUtils";
import Param from "../Params/Param";
import { useTaskDrawer } from "../../../context/TaskDrawerContext";

const ParametersTab = () => {
  const { task } = useTaskDrawer();

  return (
    <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
      <Typography variant="body1" mb={2}>
        Configuración de Parámetros
      </Typography>
      {specificParams[task.task_name].map((param) => (
        <Param param={param} isSpecific/>
      ))}
    </Box>
  );
};
export default ParametersTab;
