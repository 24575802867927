import { styled } from "@mui/material/styles";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableFooter,
} from "@mui/material";

export const ActionWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));

export const AddButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.primary.light),
  backgroundColor: theme.palette.primary.main + "20", // Adds 20% opacity
  "&:hover": {
    backgroundColor: theme.palette.primary.main + "40", // Adds 40% opacity on hover
  },
}));

export const StyledDialogButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.primary.light),
  backgroundColor: theme.palette.primary.main + "20", // Adds 20% opacity
  "&:hover": {
    backgroundColor: theme.palette.primary.main + "40", // Adds 40% opacity on hover
  },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.grey[500]),
  backgroundColor: theme.palette.grey[500] + "20", // Adds 20% opacity
  "&:hover": {
    backgroundColor: theme.palette.grey[500] + "40", // Adds 40% opacity on hover
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const StyledTableFooter = styled(TableFooter)(({ theme }) => ({
  height: theme.spacing(8),
}));
