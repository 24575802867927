const COLORS = {
  LLMClassification: "#579DFF",
  ReturnMessage: "#F87168",
  LLMGen: "#F5CD47",
  ImageDetection: "#6CC3E0",
  RAG: "#FEA362",
  GPTImageClassification: "#4BCE97",
  Checker: "#94C748",
  Retrieve: "#E774BB",
  Curl: "#9F8FEF",
  Orchestrator: "#4BB9B0",
};

export default COLORS;
