import React, { useState, useEffect } from "react";
import {
  List,
  Box,
  ListItemIcon,
  Toolbar,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  WhatsApp as WhatsAppIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  StyledDrawer,
  DrawerContainer,
  StyledListItemText,
  LogoContainer,
  StyledListItem,
} from "./SidebarStyles";
import { menuItems } from "../../utils/Config";
import { hasAccessToRoute } from "../../utils/AuthUtils";
import { Link, useLocation } from "react-router-dom";
import { LanguageSelectContainer } from "../Navbar/NavbarStyles";
import ReactCountryFlag from "react-country-flag";

const Sidebar = ({ isCollapsed, toggleSidebar, isMobile }) => {
  const { t, i18n } = useTranslation("sidebar");
  const location = useLocation();
  const [userInfo, setUserInfo] = useState({
    user_id: "",
    company: "",
    privileges: {},
  });

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserInfo({
        user_id: parsedUser.user_id,
        company: parsedUser.company,
        privileges: parsedUser.privilege,
      });
    }
  }, []);

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
  };

  return (
    <StyledDrawer
      variant="permanent"
      open={isCollapsed}
      sx={{
        width: isMobile
          ? isCollapsed
            ? "0"
            : "100vw"
          : isCollapsed
          ? "7vw"
          : "15vw",
        transition: "width 0.3s ease-in-out",
        "& .MuiDrawer-paper": {
          width: isMobile
            ? isCollapsed
              ? "0"
              : "100vw"
            : isCollapsed
            ? "7vw"
            : "15vw",
          transition: "width 0.3s ease-in-out",
          height: "100%",
          borderRadius: 0,
        },
      }}
    >
      <Toolbar>
        <LogoContainer>
          {/* Logo */}
          {!isCollapsed ? (
            <>
              <img
                src={`${process.env.PUBLIC_URL}/manuar-icon.png`}
                alt="Manuar Logo"
                style={{
                  height: "1.5vh",
                  marginRight: "0.5vw",
                }}
              />
              <img
                src={`${process.env.PUBLIC_URL}/manuar-text.png`}
                alt="Manuar Logo"
                style={{
                  height: "1.25vh",
                }}
              />
            </>
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/manuar-icon.png`}
              alt="Manuar Logo"
              style={{
                height: "2vh",
              }}
            />
          )}
        </LogoContainer>
        {/* Collapse Button */}
        <IconButton onClick={toggleSidebar}>
          {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Toolbar>

      <DrawerContainer>
        <List>
          {menuItems
            .filter((item) => hasAccessToRoute(item.section))
            .map((item, index) => {
              const isSelected = location.pathname === item.link; // Check if current route is selected
              return (
                <StyledListItem
                  button
                  onClick={toggleSidebar}
                  component={Link}
                  to={item.link}
                  key={index}
                  className={`sidebar-${item.text.toLowerCase()}`}
                  isSelected={isSelected} // Highlight if selected
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    {item.icon}
                  </ListItemIcon>
                  {!isCollapsed && (
                    <StyledListItemText primary={t(item.text.toLowerCase())} />
                  )}
                </StyledListItem>
              );
            })}
        </List>
        <Divider />

        {isMobile && (
          <LanguageSelectContainer>
            <Select
              value={i18n.language}
              onChange={handleLanguageChange}
              variant="outlined"
              size="small"
              fullWidth
              className="language-selector"
            >
              <MenuItem value="en">
                <ReactCountryFlag
                  countryCode="US"
                  svg
                  style={{ marginRight: 8 }}
                />
                {t("language_english")}
              </MenuItem>
              <MenuItem value="es">
                <ReactCountryFlag
                  countryCode="ES"
                  svg
                  style={{ marginRight: 8 }}
                />
                {t("language_spanish")}
              </MenuItem>
              <MenuItem value="pt">
                <ReactCountryFlag
                  countryCode="BR"
                  svg
                  style={{ marginRight: 8 }}
                />
                {t("language_portuguese")}
              </MenuItem>
            </Select>
          </LanguageSelectContainer>
        )}
        {/* Try on WhatsApp Button */}
        {userInfo.company === "demo" && (
          <Button
            variant="contained"
            color="success"
            href="https://wa.me/59891613592"
            sx={{ mt: 2, width: "100%" }} // Full-width button for better visibility
            startIcon={<WhatsAppIcon />}
          >
            {t("write_to_whatsapp")}
          </Button>
        )}
      </DrawerContainer>
    </StyledDrawer>
  );
};

export default Sidebar;
