export function createTaskService(tasks, setTasks, postTasksFunction) {
  return {
    isOrchestrator: (id) =>
      tasks.some((task) => task.id === id && task.orchestrator),

    isStart: (id) => tasks.some((task) => task.id === id && task.start),

    hasOrchestrator: tasks.some((task) => task.orchestrator),

    hasStart: tasks.some((task) => task.start),

    getTask: (id) => tasks.find((task) => task.id === id) || null,

    allTasks: tasks
      .filter((task) => !task.orchestrator)
      .map((task) => ({
        name: `${task.name || task.id} • ${task.task_name}`,
        id: task.id,
      })),

    createTask: (task) => {
      const updatedTasks = [...tasks, task];
      saveChanges(updatedTasks);
    },

    updateTask: (task) => {
      const updatedTasks = tasks.map((taskItem) =>
        taskItem.id === task.id ? task : taskItem
      );
      saveChanges(updatedTasks);
    },

    setNextTask: (sourceId, nextTaskId) => {
      const updatedTasks = tasks.map((taskItem) =>
        taskItem.id === sourceId
          ? { ...taskItem, next_task: nextTaskId }
          : taskItem
      );
      saveChanges(updatedTasks);
    },

    deleteTask: (id) => {
      const updatedTasks = tasks.filter((task) => task.id !== id);
      updatedTasks.forEach((task) => {
        if (task.next_task === id) {
          task.next_task = null;
        }

        if (task.category_next_task) {
          task.category_next_task = task.category_next_task.filter(
            (rule) => rule.next_task !== id
          );
        }
      });

      saveChanges(updatedTasks);
    },

    changeNodePosition: (id, position) => {
      const updatedTasks = tasks.map((task) =>
        task.id === id ? { ...task, position } : task
      );
      postTasksFunction(updatedTasks).then(() => setTasks(updatedTasks));
    },
  };

  function saveChanges(payload) {
    postTasksFunction(payload).then(() => setTasks(payload));
  }
}
