import { MarkerType } from "@xyflow/react";

export function formatNodes(tasks) {
  return tasks.map((task) => ({
    id: task.id,
    position: task.position || { x: 0, y: 0 },
    data: {
      label: task.name,
      taskType: task.orchestrator ? "Orchestrator" : task.task_name,
      description: task.description,
      start: !!task.start,
      orchestrator: !!task.orchestrator,
      end: !!task.end,
      metadataKey: task.metadata_key,
    },
    type: "custom",
  }));
}

export function formatEdges(tasks) {
  const edges = [];
  tasks.forEach((task) => {
    task.next_task &&
      edges.push({
        id: `${task.id}-${task.next_task}`,
        source: task.id,
        target: task.next_task,
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        label: "Próxima tarea",
        type: task.id === task.next_task ? "self" : "floating",
      });

    task.category_next_task &&
      task.category_next_task.forEach((rule) =>
        edges.push({
          id: `${task.id}-${rule.next_task}`,
          source: task.id,
          target: rule.next_task,
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          label: formatNextTaskRule(rule),
          type: task.id === rule.next_task ? "self" : "floating",
        })
      );
  });
  return edges;
}

export function formatNextTaskRule(nextTaskRule) {
  const { metadata_key, comparison, value } = nextTaskRule;

  switch (comparison) {
    case "exists":
      return `${metadata_key} exists`;
    case "not_exists":
      return `${metadata_key} not exists`;
    case "not_exist":
      return `${metadata_key} not exists`;
    case "in":
      return `${metadata_key} in ${
        Array.isArray(value) ? value.join(",") : value
      }`;
    case "not_in":
      return `${metadata_key} not in ${
        Array.isArray(value) ? value.join(",") : value
      }`;
    case "equal":
    case undefined:
    case "":
    case null:
      return `${metadata_key} = '${value}'`;
    case "not_equal":
      return `${metadata_key} ≠ '${value}'`;
    case "startswith":
      return `${metadata_key} starts with '${value}'`;
    default:
      throw new Error(`Invalid comparison: ${comparison}`);
  }
}
