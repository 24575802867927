import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab, Box } from "@mui/material";

import UsersTable from "./UsersTable";
import ApiKeysTable from "./ApiKeysTable";

const Users = ({ setDisplayMessage }) => {
  const { t } = useTranslation("users"); // Initialize translation
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ marginTop: 3 }}
      >
        <Tab label={t("users_tab")} />
        <Tab label={t("system_users_tab")} />
      </Tabs>

      {selectedTab === 0 && (
        <UsersTable setDisplayMessage={setDisplayMessage} />
      )}

      {selectedTab === 1 && (
        <ApiKeysTable setDisplayMessage={setDisplayMessage} />
      )}
    </Box>
  );
};

export default Users;
