import React, { useEffect } from "react";
import { useTaskDrawer } from "../../../context/TaskDrawerContext";
import { useTasks } from "../../../context/TasksContext";
import { Box, FormControlLabel, Switch, Typography } from "@mui/material";

const BooleanParam = ({ name, defaultValue, taskParamValue, isSpecific }) => {
  const { task, editTaskParam } = useTaskDrawer();
  const { isOrchestrator, isStart, hasOrchestrator, hasStart } = useTasks();

  useEffect(() => {
    if (taskParamValue === undefined) {
      editTaskParam(name, defaultValue, isSpecific, false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, defaultValue]);

  const disabled =
    (name === "start" && hasStart && !isStart(task.id)) ||
    (name === "orchestrator" && hasOrchestrator && !isOrchestrator(task.id));

  const handleChange = (e) => {
    editTaskParam(name, e.target.checked, isSpecific);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography sx={{ width: '256px' }}>{name}</Typography>
      <FormControlLabel
        disabled={disabled}
        control={<Switch checked={!!taskParamValue} onChange={handleChange} />}
      />
    </Box>
  );
};

export default BooleanParam;
