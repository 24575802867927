import React, { createContext, useState, useContext } from "react";
import { formatNodes, formatEdges } from "./taskUtils";
import { createTaskService } from "./taskService";
import useClientData from "../hooks/useClientData";

const TasksContext = createContext();

export const TasksProvider = ({ children }) => {
  const [tasks, setTasks] = useState([]);
  const { postTasks } = useClientData();

  const taskService = createTaskService(tasks, setTasks, postTasks);

  return (
    <TasksContext.Provider
      value={{
        tasks,
        setTasks,
        getNodes: () => formatNodes(tasks),
        getEdges: () => formatEdges(tasks),
        ...taskService,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export const useTasks = () => useContext(TasksContext);
