import React from 'react';
import { getBezierPath, useStore, BaseEdge, EdgeText } from '@xyflow/react';

export const getSpecialPath = (
  { sourceX, sourceY, targetX, targetY },
  offset
) => {
  const centerX = (sourceX + targetX) / 2;
  const centerY = (sourceY + targetY) / 2;

  return `M ${sourceX} ${sourceY} Q ${centerX} ${centerY + offset} ${targetX} ${targetY}`;
};

export default function BiDirectionalEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  label,
}) {
  const edges = useStore((s) => s.edges);

  const hasOppositeEdge = edges.some(
    (e) => e.source === target && e.target === source && e.id !== id
  );

  const edgePathParams = {
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  };

  const offset = hasOppositeEdge ? 60 : 0;
  const path = getSpecialPath(edgePathParams, sourceX < targetX ? offset : -offset);

  const isReverse = source > target;
  const labelX = sourceX + (targetX - sourceX) * 0.3;
  const labelY = sourceY + (targetY - sourceY) * 0.3 + (isReverse ? 20 : -20);

  const finalLabel = isReverse
    ? `${label}`
    : `${label}`;

  return (
    <>
      <BaseEdge path={path} markerEnd={markerEnd} />
      {label && (
        <EdgeText
          x={labelX}
          y={labelY}
          label={finalLabel}
          labelStyle={{ fill: '#000', fontSize: 12 }}
        />
      )}
    </>
  );
}
