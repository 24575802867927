import React from 'react';
import { getStraightPath, EdgeText, useInternalNode, useEdges } from '@xyflow/react';
import { getEdgeParams } from './utils';
import BiDirectionalEdge from './BiDirectionalEdge';

function FloatingEdge({ id, source, target, markerEnd, style, label }) {
  const sourceNode = useInternalNode(source);
  const targetNode = useInternalNode(target);
  const edges = useEdges();

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const hasReverseConnection = edges.some(
    (edge) => edge.source === target && edge.target === source && edge.id !== id
  );

  if (hasReverseConnection) {
    return (
      <BiDirectionalEdge
        id={id}
        source={source}
        target={target}
        sourceX={sx}
        sourceY={sy}
        targetX={tx}
        targetY={ty}
        sourcePosition={sourceNode.position}
        targetPosition={targetNode.position}
        markerEnd={markerEnd}
        style={style}
        label={label}
      />
    );
  }

  const offset = 10;
  const isReverse = source > target;

  const adjustedSX = sx + (hasReverseConnection && isReverse ? offset : 0);
  const adjustedSY = sy + (hasReverseConnection && !isReverse ? offset : 0);
  const adjustedTX = tx - (hasReverseConnection && isReverse ? offset : 0);
  const adjustedTY = ty - (hasReverseConnection && !isReverse ? offset : 0);

  const [edgePath] = getStraightPath({
    sourceX: adjustedSX,
    sourceY: adjustedSY,
    targetX: adjustedTX,
    targetY: adjustedTY,
  });

  const labelX = adjustedSX + (adjustedTX - adjustedSX) * 0.3;
  const labelY = adjustedSY + (adjustedTY - adjustedSY) * 0.3;

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        style={style}
      />
      {label && (
        <EdgeText
          x={labelX}
          y={labelY}
          label={label}
          labelStyle={{ fill: '#000', fontSize: 12 }}
        />
      )}
    </>
  );
}

export default FloatingEdge;