import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import modularized translation files
import enGlobal from "./locales/en/Global.json";
import enApp from "./locales/en/App.json";
import enChats from "./locales/en/Chats.json";
import enDashboard from "./locales/en/Dashboard.json";
import enDocuments from "./locales/en/Documents.json";
import enLayout from "./locales/en/Layout.json";
import enLogin from "./locales/en/Login.json";
import enNavbar from "./locales/en/Navbar.json";
import enPrivateRoute from "./locales/en/PrivateRoute.json";
import enSettings from "./locales/en/Settings.json";
import enSidebar from "./locales/en/Sidebar.json";
import enUsers from "./locales/en/Users.json";
import enLabelling from "./locales/en/Labeling.json";

import esGlobal from "./locales/es/Global.json";
import esApp from "./locales/es/App.json";
import esChats from "./locales/es/Chats.json";
import esDashboard from "./locales/es/Dashboard.json";
import esDocuments from "./locales/es/Documents.json";
import esLayout from "./locales/es/Layout.json";
import esLogin from "./locales/es/Login.json";
import esNavbar from "./locales/es/Navbar.json";
import esPrivateRoute from "./locales/es/PrivateRoute.json";
import esSettings from "./locales/es/Settings.json";
import esSidebar from "./locales/es/Sidebar.json";
import esUsers from "./locales/es/Users.json";
import esLabelling from "./locales/es/Labeling.json";

import ptGlobal from "./locales/pt/Global.json";
import ptApp from "./locales/pt/App.json";
import ptChats from "./locales/pt/Chats.json";
import ptDashboard from "./locales/pt/Dashboard.json";
import ptDocuments from "./locales/pt/Documents.json";
import ptLayout from "./locales/pt/Layout.json";
import ptLogin from "./locales/pt/Login.json";
import ptNavbar from "./locales/pt/Navbar.json";
import ptPrivateRoute from "./locales/pt/PrivateRoute.json";
import ptSettings from "./locales/pt/Settings.json";
import ptSidebar from "./locales/pt/Sidebar.json";
import ptUsers from "./locales/pt/Users.json";
import ptLabelling from "./locales/pt/Labeling.json";

const storedLanguage = localStorage.getItem("language") || "es";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      global: enGlobal,
      app: enApp,
      chats: enChats,
      dashboard: enDashboard,
      documents: enDocuments,
      layout: enLayout,
      login: enLogin,
      navbar: enNavbar,
      privateRoute: enPrivateRoute,
      settings: enSettings,
      sidebar: enSidebar,
      users: enUsers,
      labeling: enLabelling,
    },
    es: {
      global: esGlobal,
      app: esApp,
      chats: esChats,
      dashboard: esDashboard,
      documents: esDocuments,
      layout: esLayout,
      login: esLogin,
      navbar: esNavbar,
      privateRoute: esPrivateRoute,
      settings: esSettings,
      sidebar: esSidebar,
      users: esUsers,
      labeling: esLabelling,
    },
    pt: {
      global: ptGlobal,
      app: ptApp,
      chats: ptChats,
      dashboard: ptDashboard,
      documents: ptDocuments,
      layout: ptLayout,
      login: ptLogin,
      navbar: ptNavbar,
      privateRoute: ptPrivateRoute,
      settings: ptSettings,
      sidebar: ptSidebar,
      users: ptUsers,
      labeling: ptLabelling,
    },
  },
  lng: storedLanguage,
  fallbackLng: "es", // Fallback language if the selected language is not available
  ns: [
    "global",
    "app",
    "chats",
    "dashboard",
    "documents",
    "layout",
    "login",
    "navbar",
    "privateRoute",
    "settings",
    "sidebar",
    "users",
  ], // Declare namespaces
  defaultNS: "global", // Default namespace
  fallbackNS: "global", // Fallback namespace
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
