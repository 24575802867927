import { styled } from "@mui/material/styles";
import { Box, Paper, Typography, Button, TextField } from "@mui/material";

export const DayBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

export const CurrentDayBox = styled(DayBox)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  border: `2px solid ${theme.palette.primary.main}`,
}));

export const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
}));

export const TabWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
}));

export const TabHeader = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
}));

export const TabPanelContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  marginBottom: theme.spacing(3),
}));

export const SettingsForm = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  width: "100%",
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  fontSize: theme.typography.h6.fontSize,
}));

export const ListItem = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
}));

export const RemoveButton = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const AddButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const FormControl = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: "100%",
}));

export const ListContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

export const ListTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(1),
}));

export const ListItemText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.text.primary,
}));
