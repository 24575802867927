import React, { useEffect, useState } from "react";
import { useTaskDrawer } from "../../../context/TaskDrawerContext";
import {
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import COLORS from "../../../styles/colors";

const OptionsParam = ({
  name,
  defaultValue = null,
  options = [],
  isMultiple = false,
  taskParamValue,
  isSpecific,
}) => {
  const { editTaskParam } = useTaskDrawer();
  const [selectedOptions, setSelectedOptions] = useState(
    defaultValue || (isMultiple ? [] : null)
  );

  useEffect(() => {
    if (taskParamValue === undefined) {
      editTaskParam(name, defaultValue, isSpecific, false);
    } else {
      setSelectedOptions(taskParamValue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, defaultValue]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOptions(value);
    editTaskParam(name, value, isSpecific);
  };

  function CustomMenuItem({ color, value, children, ...props }) {
    return (
      <MenuItem
        value={value}
        {...props}
        style={{
          color:
            selectedOptions.includes(value) && name === "task_name"
              ? color
              : undefined,
        }}
      >
        {name === "task_name" && (
          <ListItemIcon>
            <Circle color={color} />
          </ListItemIcon>
        )}
        {children}
      </MenuItem>
    );
  }

  function Circle({ color }) {
    return (
      <div
        style={{
          width: 12,
          height: 12,
          borderRadius: "50%",
          backgroundColor: color,
          marginRight: 8,
        }}
      />
    );
  }

  return (
    <Box sx={{ gap: 1, display: "flex", flexDirection: "column" }}>
      <Typography>{name}</Typography>
      <FormControl fullWidth>
        <InputLabel>
          {isMultiple ? "Seleccionar opciones" : "Seleccionar opción"}
        </InputLabel>
        <Select
          multiple={isMultiple}
          value={selectedOptions}
          onChange={handleChange}
          renderValue={(selected) =>
            isMultiple
              ? selected.join(", ")
              : selected || "Seleccione una opción"
          }
        >
          {options.map((option) => (
            <CustomMenuItem key={option} value={option} color={COLORS[option]}>
              {isMultiple && (
                <Checkbox checked={selectedOptions.includes(option)} />
              )}
              <ListItemText primary={option} />
            </CustomMenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default OptionsParam;
