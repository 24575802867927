import React, { useEffect, useState } from "react";
import { useTaskDrawer } from "../../../context/TaskDrawerContext";
import { TextField } from "@mui/material";
import { debounce } from "lodash";

const NumberParam = ({ name, defaultValue, taskParamValue, isSpecific }) => {
  const { editTaskParam } = useTaskDrawer();
  const [paramValue, setParamValue] = useState(defaultValue);

  useEffect(() => {
    if (taskParamValue === undefined) {
      editTaskParam(name, defaultValue, isSpecific, false);
    } else {
      setParamValue(taskParamValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, defaultValue]);

  const handleChange = (value) => {
    setParamValue(value);
    debouncedEditTaskParam(value);
  };

  const debouncedEditTaskParam = debounce((value) => {
    editTaskParam(name, value, isSpecific);
  }, 500);

  return (
    <TextField
      label={name}
      placeholder="Ingrese un número"
      type="number"
      fullWidth
      margin="normal"
      value={paramValue}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export default NumberParam;
